import './GlPeriodDetailTabs.css'
import { Nav, NavLink } from 'reactstrap';
import AuditOverallPopulationStatus from './AuditOverallPopulationStatus';
import IncompleteBillReviews from './IncompleteBillReviews';
import PreviusPeriodWips from './PreviusPeriodWips';
import { AllActionsFlags, AuditOverallPopulationJobFlag, GlPeriodViewModel } from './GlPeriodDetailsLayout';
import OrgUnitsOnTimesheets from './OrgUnitsOnTimesheets';
import SaleItemsByBaseCodes from './SaleItemsByBaseCodes';
import SaleItemsWithMismatchingInvoicePeriod from './SaleItemsWithMismatchingInvoicePeriod';
import Wip from './Wip';
import FixedFee from './FixedFee';
import TnMandNte from './TnMandNte';
import Hybrid from './Hybrid';
import Reports from './Reports';
import ExpenseDistribution from './ExpenseDistribution';
import FixedFeenHybridVariance from './FxedFeenHybridVariance';
import SaleItemsForNteFix from './SaleItemsForNteFix';

type GlPeridDetailsTabsProps = {
    glPeriod: GlPeriodViewModel;
    getActiveSectionId: () => string;
    setActiveSectionId: (id: string) => void;
    refreshGlPeriodData: () => void;
};

const GlPeridDetailsTabs = (props: GlPeridDetailsTabsProps) => {
    const getTabClassName = (id?: string, defaultToActive?: boolean): string => {
        if (id === props.getActiveSectionId() || (defaultToActive && !props.getActiveSectionId())) {
            return "tab-pane fade show active";
        }
        return "tab-pane fade";
    };

    return <>
        <div className="d-flex w-100">
            <Nav id="glPeriodsDetailsTabs" className="flex-column nav-tabs d-none d-md-block mt-5 mw-25">
                <NavLink href="#" active={!props.getActiveSectionId() || props.getActiveSectionId() === 'auditOverallPopulation'} aria-current="page" data-bs-toggle="tab" data-bs-target="#auditOverallPopulation" role="tab" aria-controls="auditOverallPopulation" aria-selected="true" onClick={() => props.setActiveSectionId("auditOverallPopulation")}>Audit-Overall-Population Job</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'incompleteBillReviews'} data-bs-toggle="tab" data-bs-target="#incompleteBillReviews" role="tab" aria-controls="incompleteBillReviews" aria-selected="false" onClick={() => props.setActiveSectionId("incompleteBillReviews")}>Incomplete Bill Reviews</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'previousPeriodWips'} data-bs-toggle="tab" data-bs-target="#previousPeriodWips" role="tab" aria-controls="previousPeriodWips" aria-selected="false" onClick={() => props.setActiveSectionId("previousPeriodWips")}>Previous Period WIP</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'orgUnitsOnTimesheets'} data-bs-toggle="tab" data-bs-target="#orgUnitsOnTimesheets" role="tab" aria-controls="orgUnitsOnTimesheets" aria-selected="false" onClick={() => props.setActiveSectionId("orgUnitsOnTimesheets")}>Org.-Unit Differences on Timesheets</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'removeSalesItemsByBaseCodes'} data-bs-toggle="tab" data-bs-target="#removeSalesItemsByBaseCodes" role="tab" aria-controls="removeSalesItemsByBaseCodes" aria-selected="false" onClick={() => props.setActiveSectionId("removeSalesItemsByBaseCodes")}>Sale Items by Base Codes</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'removeSalesItemsForNte'} data-bs-toggle="tab" data-bs-target="#removeSalesItemsForNte" role="tab" aria-controls="removeSalesItemsForNte" aria-selected="false" onClick={() => props.setActiveSectionId("removeSalesItemsForNte")}>NTE Check</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'periodMismatches'} data-bs-toggle="tab" data-bs-target="#periodMismatches" role="tab" aria-controls="periodMismatches" aria-selected="false" onClick={() => props.setActiveSectionId("periodMismatches")}>Sale Items w/ Invoice Period Mismatch</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'expenseDistribution'} data-bs-toggle="tab" data-bs-target="#expenseDistribution" role="tab" aria-controls="expenseDistribution" aria-selected="false" onClick={() => props.setActiveSectionId("expenseDistribution")}>Expense Distribution</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'wip'} data-bs-toggle="tab" data-bs-target="#wip" role="tab" aria-controls="wip" aria-selected="false" onClick={() => props.setActiveSectionId("wip")}>WIP</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'billedFixedFee'} data-bs-toggle="tab" data-bs-target="#billedFixedFee" role="tab" aria-controls="billedFixedFee" aria-selected="false" onClick={() => props.setActiveSectionId("billedFixedFee")}>Billed Fixed Fee</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'billedHybrid'} data-bs-toggle="tab" data-bs-target="#billedHybrid" role="tab" aria-controls="billedHybrid" aria-selected="false" onClick={() => props.setActiveSectionId("billedHybrid")}>Billed Hybrid</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'billedTnM_NTE'} data-bs-toggle="tab" data-bs-target="#billedTnM_NTE" role="tab" aria-controls="billedTnM_NTE" aria-selected="false" onClick={() => props.setActiveSectionId("billedTnM_NTE")}>Billed T&M NTE</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'fixedFeenHybridVariance'} data-bs-toggle="tab" data-bs-target="#fixedFeenHybridVariance" role="tab" aria-controls="fixedFeenHybridVariance" aria-selected="false" onClick={() => props.setActiveSectionId("fixedFeenHybridVariance")}>Fixed Fee/Hybrid Variance</NavLink>
                <NavLink href="#" active={props.getActiveSectionId() === 'reports'} data-bs-toggle="tab" data-bs-target="#reports" role="tab" aria-controls="reports" aria-selected="false" onClick={() => props.setActiveSectionId("reports")}>Reports</NavLink>
            </Nav>
            <div className="tab-content flex-fill" id="glPeriod-details-tabContent">
                <div className={getTabClassName("auditOverallPopulation", true)} id="auditOverallPopulation" role="tabpanel" aria-labelledby="auditOverallPopulation-tab"><AuditOverallPopulationStatus version={props.glPeriod.flags & AuditOverallPopulationJobFlag} /></div>
                <div className={getTabClassName("incompleteBillReviews")} id="incompleteBillReviews" role="tabpanel" aria-labelledby="incompleteBillReviews-tab"><IncompleteBillReviews period={props.glPeriod} refreshData={props.refreshGlPeriodData} /></div>
                <div className={getTabClassName("previousPeriodWips")} id="previousPeriodWips" role="tabpanel" aria-labelledby="previousPeriodWips-tab"><PreviusPeriodWips previousPeriod={props.glPeriod.previousPeriod} previousPeriodWipGjId={props.glPeriod.previousPeriodWipGjId} previousPeriodWipReversalGjId={props.glPeriod.previousPeriodWipReversalGjId} /></div>
                <div className={getTabClassName("orgUnitsOnTimesheets")} id="orgUnitsOnTimesheets" role="tabpanel" aria-labelledby="orgUnitsOnTimesheets-tab"><OrgUnitsOnTimesheets period={props.glPeriod} /> </div>
                <div className={getTabClassName("removeSalesItemsByBaseCodes")} id="removeSalesItemsByBaseCodes" role="tabpanel" aria-labelledby="removeSalesItemsByBaseCodes-tab"><SaleItemsByBaseCodes period={props.glPeriod} refreshData={props.refreshGlPeriodData} /></div>
                <div className={getTabClassName("removeSalesItemsForNte")} id="removeSalesItemsForNte" role="tabpanel" aria-labelledby="removeSalesItemsForNte-tab"><SaleItemsForNteFix period={props.glPeriod} refreshData={props.refreshGlPeriodData} /></div>
                <div className={getTabClassName("periodMismatches")} id="periodMismatches" role="tabpanel" aria-labelledby="periodMismatches-tab"><SaleItemsWithMismatchingInvoicePeriod period={props.glPeriod} refreshData={props.refreshGlPeriodData} /></div>
                <div className={getTabClassName("expenseDistribution")} id="expenseDistribution" role="tabpanel" aria-labelledby="expenseDistribution-tab"><ExpenseDistribution period={props.glPeriod} /></div>
                <div className={getTabClassName("wip")} id="wip" role="tabpanel" aria-labelledby="wip-tab"><Wip period={props.glPeriod} /></div>
                <div className={getTabClassName("billedFixedFee")} id="billedFixedFee" role="tabpanel" aria-labelledby="billedFixedFee-tab"><FixedFee period={props.glPeriod} /></div>
                <div className={getTabClassName("billedHybrid")} id="billedHybrid" role="tabpanel" aria-labelledby="billedHybrid-tab"><Hybrid period={props.glPeriod} /></div>
                <div className={getTabClassName("billedTnM_NTE")} id="billedTnM_NTE" role="tabpanel" aria-labelledby="billedTnM_NTE-tab"><TnMandNte period={props.glPeriod} /></div>
                <div className={getTabClassName("fixedFeenHybridVariance")} id="fixedFeenHybridVariance" role="tabpanel" aria-labelledby="fixedFeenHybridVariance-tab"><FixedFeenHybridVariance period={props.glPeriod} refreshData={props.refreshGlPeriodData} /></div>
                <div className={getTabClassName("reports")} id="reports" role="tabpanel" aria-labelledby="reports-tab"><Reports period={props.glPeriod} version={props.glPeriod.flags & AllActionsFlags} /></div>
            </div>
        </div>
    </>;
};

export default GlPeridDetailsTabs;