import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AuditLog from './components/AuditLog';
import GlPeriodDetails from './components/GlPeriodDetails/GlPeriodDetails';
import GlPeriods from "./components/GlPeriods";
import Home from "./components/Home";
import ServiceSettings from './components/ServiceSettings/ServiceSettings';

export type AppRoute = {
    index?: boolean;
    element: JSX.Element,
    path?: string,
    requireAuth?: boolean;
};

const AppRoutes: AppRoute[] = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/auditLog',
        requireAuth: true,
        element: <AuditLog />
    },
    {
        path: '/accountingPeriods',
        requireAuth: true,
        element: <GlPeriods />
    },
    {
        path: '/glPeriodDetails',
        requireAuth: true,
        element: <GlPeriodDetails />
    },
    {
        path: '/settings',
        requireAuth: true,
        element: <ServiceSettings />
    },
    ...ApiAuthorzationRoutes
];

export default AppRoutes;
