const Home = (props: any) => {
    return (
        <div className="container">
            <h1>Hello!</h1>
            <p>Welcome to your new FACS portal!</p>
            <div className="w-100"><img className="img-fluid" src="/Home.jpg" alt="Welcome to your new LOS application!"></img></div>
        </div>
    );
}

export default Home;