import { useState, useEffect } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import SaleItemsWithMismatchingInvoicePeriodFilterDetails from './SaleItemsWithMismatchingInvoicePeriodFilterDetails';
import SaleItemsListWithMismatchingInvoicePeriod from './SaleItemsListWithMismatchingInvoicePeriod';
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';
import AdjustmentItems from './AdjustmentItems';
import SaleItemsWithMismatchingInvoicePeriodFilter from './SaleItemsWithMismatchingInvoicePeriodFilter';
import authService from '../api-authorization/AuthorizeService'

type SaleItemsWithMismatchingInvoicePeriodProps = {
    period: GlPeriodViewModel;
    refreshData: () => void;
};

const SaleItemsWithMismatchingInvoicePeriod = (props: SaleItemsWithMismatchingInvoicePeriodProps) => {
    const [openSections, setOpenSections] = useState(["ItemFilters", "ItemFilterDetails", "SalesItems", "AdjustmentItems"]);
    const [submittingData, setSubmittingData] = useState(false);
    const [statusText, setStatusText] = useState("");
    const [excludedFirmIds, setExcludedFirmIds] = useState(props.period.invoicePeriodFixExcludedFirmIds);

    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    useEffect(() => {
        const updateBasedCodeIDsForSjRemoval = async () => {
            setSubmittingData(true);

            const url = `glPeriodFacts/${props.period.factId!.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                method: 'PATCH',
                headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
                body: JSON.stringify({
                    invoicePeriodFixExcludedFirmIds: excludedFirmIds,
                })
            });
            if (response.ok) {
                try {
                    await response.json();
                    setStatusText("");
                    props.refreshData();
                } catch (error) {
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setStatusText(await response.text());
            }
            setSubmittingData(false);
        }
        if (props.period.factId && excludedFirmIds !== props.period.invoicePeriodFixExcludedFirmIds) {
            updateBasedCodeIDsForSjRemoval();
        }
    }, [excludedFirmIds, props, props.period.invoicePeriodFixExcludedFirmIds, props.period.factId, props.refreshData]);

    return (<div className="container">
        {statusText ? <div className="row"><div className="col-12"><div className="alert alert-danger" role="alert">
            <div>{statusText}</div>
        </div></div></div> : <></>}
        {
            //@ts-ignore
            <Accordion open={openSections} toggle={setOpenSection}>
                <AccordionItem>
                    <AccordionHeader targetId="ItemFilter">Filter for Sale Items w/ Mismatching Invoice Period</AccordionHeader>
                    <AccordionBody accordionId="ItemFilter">
                        <SaleItemsWithMismatchingInvoicePeriodFilter excludedFirmIds={excludedFirmIds}
                            setExcludedFirmIds={setExcludedFirmIds}
                            submittingData={submittingData} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="ItemFilterDetails">Excluded Firms</AccordionHeader>
                    <AccordionBody accordionId="ItemFilterDetails">
                        <SaleItemsWithMismatchingInvoicePeriodFilterDetails excludedFirmIds={excludedFirmIds} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="SalesItems">Sales Items w/ Mismatching Invoice Period</AccordionHeader>
                    <AccordionBody accordionId="SalesItems">
                        <SaleItemsListWithMismatchingInvoicePeriod periodId={props.period.periodId} excludedFirmIds={excludedFirmIds} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="AdjustmentItems">Adjustment for Sale Items w/ Mismatching Invoice Period</AccordionHeader>
                    <AccordionBody accordionId="AdjustmentItems">
                        <AdjustmentItems adjustmentId={props.period.invoicePeriodFixAdjustmentId} />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        }
    </div>);
};

export default SaleItemsWithMismatchingInvoicePeriod;