import { useState } from 'react';
import { formatCurrency } from '../../Utils';
import TablePager from '../TablePager';
import { GlAmountDetail } from './GlAmountDetails';
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';

type GlAmountDetailsByOrgUnitsProps = {
    period: GlPeriodViewModel;
    amounts: GlAmountDetail[];
    accType: string;
    orgPath: string;
    loading: boolean;
};

const listPageSize = 25;

const GlAmountDetailsByOrgUnits = (props: GlAmountDetailsByOrgUnitsProps) => {
    const [startIndex, setStartIndex] = useState(0);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const pagedItems = props.amounts.slice(startIndex, startIndex + listPageSize);

    const renderAmount = (amount: GlAmountDetail) => {
        return <tr key={'amountDetailsByOrg' + amount.baseCode + amount.glAccountName + (amount.orgPath ?? "") + amount.itemType}>
                <td>{amount.itemType}</td>
                <td>{formatCurrency(amount.previousPeriodAmount ?? 0)}</td>
                <td>{formatCurrency(amount.amount)}</td>
            </tr>
    };

    return (<>
        <div className="row">
            <div className="col-12 table-responsive-md fst-italic small">
                <table className="table table-striped table-hover" aria-labelledby="tableLabel">
                    <thead>
                        <tr>
                            <th>Item Type</th>
                            <th>{props.period.previousPeriod?.periodCode ?? "-"}</th>
                            <th>{props.period.periodCode}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pagedItems.length > 0 ? pagedItems.map((amount) => renderAmount(amount))
                            : <tr><td colSpan={3} style={{ textAlign: 'center' }}>{props.loading ? "Loading data..." : `No items for ${props.orgPath} were found.`}</td></tr>}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row">
            <TablePager ariaLabel="Page navigation for amount details by org. units" totalCount={props.amounts.length}
                itemCount={pagedItems.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="amountDetailsByOrgUnits" />
        </div>
    </>);
};

export default GlAmountDetailsByOrgUnits;