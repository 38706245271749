import { useState, useEffect, useId } from 'react';
import { formatDateTime } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'

type InfocusJob = {
    jobID: number;
    jobName: string;
    description: string | null;
    isActive: boolean;
    nextRunDate: string | null;
    endDate: string | null;
    runFrequency: number;
    runFrequencyType: string;
    jobRunCount: number;
    contextEmpID: number | null;
    jobLanguage: string;
    processMissedRuns: boolean;
    modifyDate: string;
    createDate: string;
    modifyBy: string;
    createBy: string;
    dayMask: string;
    allowMultipleInstances: boolean;
    webhook: string | null;
    webhookExpiration: string | null;
};


const AuditOverallPopulationJob = (props: any) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [loading, setLoading] = useState(true);
    const [infocusJob, setInfocusJob] = useState<InfocusJob | null>(null);

    useEffect(() => {
        const populateInfocusJob = async () => {
            const queryParams = new URLSearchParams({
                name: "z_AuditOverallPopulation"
            });
            const url = `inFocusJobs?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setInfocusJob(data);
                    setStatusText("");
                } catch (error) {
                    setInfocusJob(null);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setInfocusJob(null);
                setStatusText(await response.text());
            }
            setLoading(false);
        }
        populateInfocusJob();
    }, []);

    const jobStatusFormId = useId();

    const renderJobStatus = () => {
        return (infocusJob ? <>
            <div className={infocusJob.isActive ? "row mb-3" : "row mb-3 text-secondary"}>
                <div className="form-group col-md-4">
                    <label htmlFor={jobStatusFormId + "-jobId"}>Job ID:</label>
                    <input type="text" className="form-control" id={jobStatusFormId + "-jobId"} readOnly value={infocusJob.jobID.toString()} />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor={jobStatusFormId + "-name"}>Job Name:</label>
                    <input type="text" className="form-control" id={jobStatusFormId + "-name"} readOnly value={infocusJob.jobName} />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor={jobStatusFormId + "-nextRunDate"}>Next Run:</label>
                    <input type="text" className="form-control" id={jobStatusFormId + "-nextRunDate"} readOnly value={infocusJob.nextRunDate ? formatDateTime(infocusJob.nextRunDate) : ""} />
                </div>
            </div>
            <div className={infocusJob.isActive ? "row mb-3" : "row mb-3 text-secondary"}>
                <div className="form-group col-md-4">
                    <label htmlFor={jobStatusFormId + "-webHook"}>Web Hook:</label>
                    <input type="text" className="form-control" id={jobStatusFormId + "-webHook"} readOnly value={infocusJob.webhook ?? ""} />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor={jobStatusFormId + "-webhookExp"}>Web Hook Exp:</label>
                    <input type="text" className="form-control" id={jobStatusFormId + "-webhookExp"} readOnly value={infocusJob.webhookExpiration ? formatDateTime(infocusJob.webhookExpiration) : ""} />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor={jobStatusFormId + "-lastMod"}>Last Mod:</label>
                    <input type="text" className="form-control" id={jobStatusFormId + "-lastMod"} readOnly value={formatDateTime(infocusJob.modifyDate)} />
                </div>
            </div>
            <div className="row mt-2 mb-2 text-black-50">
                <div className="form-group col-md-6 d-flex justify-content-center">
                    {infocusJob.isActive ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>}&nbsp;Job Is Active
                </div>
                <div className="form-group col-md-6 d-flex justify-content-center">
                    {infocusJob.allowMultipleInstances ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>}&nbsp;Allow Multiple Job Instances
                </div>
            </div>
        </> : <></>);
    }

    let contents = loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderJobStatus();

    return (
        <div>
            {contents}
        </div>
    );
};

export default AuditOverallPopulationJob;