import React from 'react';
import { formatDate, formatDateIgnoreTimeZone } from '../../Utils';
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';

type GlPeriodHeaderProps = {
    glPeriod: GlPeriodViewModel;
};

const GlPeriodHeader = (props: GlPeriodHeaderProps) => {    
    return <div className="row">
        <div className="col-md-6">
            <h1 id="peridCodeLabel">Accounting Period {props.glPeriod.periodCode}</h1>
            <span className="fw-bold">Period ID:</span> {props.glPeriod.periodId.toString()}<br />
            <span className="fw-bold">StartDate:</span> {formatDateIgnoreTimeZone(props.glPeriod.startDate)}<br />
            <span className="fw-bold">End Date:</span> {formatDateIgnoreTimeZone(props.glPeriod.endDate)}<br />
        </div>
        <div className="col-md-6 d-flex justify-content-md-end">
            <div style={{ display: 'grid', alignContent: 'end', justifyContent: 'end' }}>
                <span><span className="fw-bold">Status:</span> {props.glPeriod.isOpen ? "Open" : "Closed"}</span>
                <span><span className="fw-bold">Allow Adjustments:</span> {props.glPeriod.allowAdjustments ? "Yes" : "No"}</span>
                <span><span className="fw-bold"># Work days:</span> {props.glPeriod.workDays.toString()}</span>
                <span><span className="fw-bold">Month End Close Date:</span> {props.glPeriod.closedAt ? formatDate(props.glPeriod.closedAt) : "-"}</span>
                <span><span className="fw-bold">Month End Closed By:</span> {props.glPeriod.closedBy ?? "-"}</span>
            </div>
        </div>
    </div>
};

export default GlPeriodHeader;