import { useState, useEffect } from 'react';
import { formatDate } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';

export type ClosedProject = {
    projectID: number;
    projectName: string;
    contractType: string | null;
    serviceLine: string | null;
    finalInvoiceDate: string;
};

const listPageSize = 25;

type EffortsOnClosedProjectsProps = {
    period: GlPeriodViewModel;
};

const EffortsOnClosedProjects = (props: EffortsOnClosedProjectsProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [closedProjects, setClosedProjects] = useState<ClosedProject[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateClosedProjects = async () => {
            const queryParams = new URLSearchParams({
                startTime: props.period.startDate,
                endTime: props.period.endDate,
                excludedFactIds: props.period.varianceExcludedFactIds,
                excludedOrgCodes: props.period.varianceExcludedOrgCodes,
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `projects/closed?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setClosedProjects(data.projects ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setClosedProjects([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setClosedProjects([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }
        populateClosedProjects();
    }, [startIndex,
        props.period.startDate,
        props.period.endDate,
        props.period.varianceExcludedFactIds,
        props.period.varianceExcludedOrgCodes]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const renderClosedProjectsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>Project ID</th>
                                <th>Name</th>
                                <th>Contract Type</th>
                                <th>Service Line</th>
                                <th>Final Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {closedProjects.length > 0 ? closedProjects.map(entry =>
                                <tr key={entry.projectID}>
                                    <td>{entry.projectID}</td>
                                    <td>{entry.projectName}</td>
                                    <td>{entry.contractType}</td>
                                    <td>{entry.serviceLine}</td>
                                    <td>{formatDate(entry.finalInvoiceDate)}</td>
                                </tr>
                            ) : <tr><td colSpan={5} style={{ textAlign: 'center' }}>There are no closed projects in this accounting period.</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for closed projects" totalCount={totalCount}
                    itemCount={closedProjects.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="closedProjects" />
            </div>
        </>);
    }

    let contents = loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderClosedProjectsTable();

    return (
        <div>
            {contents}
        </div>
    );
};

export default EffortsOnClosedProjects;