import React, { useState, useEffect } from 'react';
import { formatCurrency, formatDate } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';
import { SaleItem, buildProjectDesc } from './SaleItemsByBaseCodesList';

type SaleItemsListWithMismatchingInvoicePeriodProps = {
    periodId: number | null;
    excludedFirmIds: string;
};

type SaleTransViewModel = {
    periodCode: string;
    transCode1: string;
    financialTypeCode: string;
    amount: number;
};

type SaleItemWithInvoicePeriod = SaleItem & {
    invoicePeriodCode: string | null;
    mismatchingTransactions: SaleTransViewModel[];
};

const listPageSize = 25;

const SaleItemsListWithMismatchingInvoicePeriod = (props: SaleItemsListWithMismatchingInvoicePeriodProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [saleItems, setSaleItems] = useState<SaleItemWithInvoicePeriod[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateSaleItems = async () => {
            const queryParams = new URLSearchParams({
                periodId: props.periodId?.toString() ?? '',
                excludedFirmIds: props.excludedFirmIds,
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `saleItems/withInvoicePeriods?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setSaleItems(data.items ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setSaleItems([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setSaleItems([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }

        populateSaleItems();
    }, [startIndex, props.excludedFirmIds, props.periodId]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const itemsByPeriod = saleItems.reduce((groups, item) => {
        const groupKey = (item.invoicePeriodCode ?? '');
        if (!groups.has(groupKey)) {
            groups.set(groupKey, []);
        }
        groups.get(groupKey)?.push(item);
        return groups;
    }, new Map<string, SaleItemWithInvoicePeriod[]>());

    const renderItem = (item: SaleItemWithInvoicePeriod) => {
        return <React.Fragment key={item.sjLineId.toString()}>
            <tr>
                <td colSpan={4}>
                    Item ID {item.sjLineId.toString()}, GL Account "{item.glName ?? "-"}", Period {item.periodCode}, <span title={buildProjectDesc(item)}>Project "{item.projectCode ?? "-"}"</span>
                </td>
            </tr>
            {item.mismatchingTransactions.map(trans => <tr key={trans.periodCode + trans.transCode1 + trans.financialTypeCode}>
                <td>{trans.periodCode}</td>
                <td>{trans.transCode1}</td>
                <td>{trans.financialTypeCode}</td>
                <td>{formatCurrency(trans.amount)}</td>
            </tr>)}
        </React.Fragment>
    }

    const renderItemsBySales = (items: SaleItemWithInvoicePeriod[]) => {
        const groupLeader = items[0];
        const groupKey = groupLeader.sjId.toString();

        return <React.Fragment key={groupKey}>
            <tr key={groupKey}>
                <td colSpan={4}>Sale ID {groupKey}: {groupLeader.firmName}, {formatDate(groupLeader.modifyDate)}</td>
            </tr>
            {items.map(item => renderItem(item))}
        </React.Fragment>
    };

    const renderByPeriods = (items: SaleItemWithInvoicePeriod[]) => {
        const groupLeader = items[0];
        const groupKey = (groupLeader.invoicePeriodCode ?? '');

        const itemsBySale = items.reduce((groups, item) => {
            const groupKey = item.sjId.toString();
            if (!groups.has(groupKey)) {
                groups.set(groupKey, []);
            }
            groups.get(groupKey)?.push(item);
            return groups;
        }, new Map<string, SaleItemWithInvoicePeriod[]>());

        return <React.Fragment key={groupKey}>
            <tr key={groupKey}>
                <td colSpan={4}>Invoice Period Code "{groupLeader.invoicePeriodCode ?? '-'}"</td>
            </tr>
            {Array.from(itemsBySale).map(([, itemsInGroup]) => renderItemsBySales(itemsInGroup))}
        </React.Fragment>
    };

    const renderSaleItemsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>Trans. Period</th>
                                <th>Trans Code1</th>
                                <th>Fin. Type Code</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {itemsByPeriod.size > 0 ? Array.from(itemsByPeriod).map(([, itemsInGroup]) => renderByPeriods(itemsInGroup))
                                : <tr><td colSpan={4} style={{ textAlign: 'center' }}>The filtered sales items list is empty.</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for timesheet items" totalCount={totalCount}
                    itemCount={saleItems.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="saleItemsWithInvPeriod" />
            </div>
        </>);
    }

    return loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderSaleItemsTable()
};

export default SaleItemsListWithMismatchingInvoicePeriod;