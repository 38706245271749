import { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import ExpenseItems from './ExpenseItems';
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';
import AdjustmentItems from './AdjustmentItems';

type ExpenseDistributionProps = {
    period: GlPeriodViewModel;
};

const ExpenseDistribution = (props: ExpenseDistributionProps) => {
    const [openSections, setOpenSections] = useState(["ExpenseItems", "AdjustmentItems"]);
    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    return (<div className="container">
        {
            //@ts-ignore
            <Accordion open={openSections} toggle={setOpenSection}>
                <AccordionItem>
                    <AccordionHeader targetId="ExpenseItems">Expense Items</AccordionHeader>
                    <AccordionBody accordionId="ExpenseItems">
                        <ExpenseItems period={props.period} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="AdjustmentItems">Adjustment for Expense Items</AccordionHeader>
                    <AccordionBody accordionId="AdjustmentItems">
                        <AdjustmentItems adjustmentId={props.period.expenseGjId} />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        }
    </div>);
};

export default ExpenseDistribution;