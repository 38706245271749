import { GlPeriodViewModel } from "./GlPeriodDetailsLayout";

type IncompleteBillReviewFiltersProps = {
    period: GlPeriodViewModel;
    cutoffDate: Date;
    setCutoffDate: (date: Date) => void;
    submittingData: boolean;
};

const validateCutoffDate = (date: Date, minDate: string, maxDate: string): boolean => {
    if (isNaN(date.getTime())){
        return false;
    }

    return date >= new Date(minDate) && date <= new Date(maxDate);
}

const IncompleteBillReviewFilters = (props: IncompleteBillReviewFiltersProps) => {
    const onCutoffDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { classList: targetClassList, value: targetValue } = e.target;
        const date = new Date(targetValue);
        if (validateCutoffDate(date, props.period.startDate, props.period.endDate)) {
            targetClassList.remove("is-invalid");
            targetClassList.add("is-valid");
            props.setCutoffDate(date);
        } else {
            targetClassList.remove("is-valid");
            targetClassList.add("is-invalid");
        }
    };

    return (<div className="row mb-3">
        <div className="form-group col-md-4">
            <label htmlFor="incompleteBillReviewCutoffDate">Cutoff Date:</label>
            <input type="date" className="form-control" id="incompleteBillReviewCutoffDate"
                disabled={props.submittingData}
                value={props.cutoffDate.toISOString().split('T')[0]} onChange={(e) => onCutoffDateChange(e) } />
        </div>
        <div className="form-group col-md-4">
            <label htmlFor="incompleteBillReviewModifiedBy">Modified by:</label>
            <input type="text" className="form-control" id="incompleteBillReviewModifiedBy" readOnly value="Action%" />
        </div>
        <div className="form-group col-md-4">
            <label htmlFor="incompleteBillReviewNotReviewedBy">Not Reviewed by:</label>
            <input type="text" className="form-control" id="incompleteBillReviewNotReviewedBy" readOnly value="Project Accountant" />
        </div>
    </div>);
};

export default IncompleteBillReviewFilters;