import { useState, useEffect, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';

const LoginMenu = (props: any) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userName, setUserName] = useState<string | null>(null);
    const [subscriptionId, setSubscriptionId] = useState(-1);

    useEffect(() => {
        const populateState = async () => {
            const [authenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
            setIsAuthenticated(authenticated);
            setUserName((user as any)?.name ?? null);
            if (authenticated && subscriptionId <= 0) {
                const newSubscriptionId = authService.subscribe(() => {
                    setIsAuthenticated(false);
                    populateState();
                });
                setSubscriptionId(newSubscriptionId);
            }
        }

        populateState();

        // Optional cleanup code when the component is unmounted or re-rendered
        return () => {
            if (subscriptionId > 0) {
                authService.unsubscribe(subscriptionId);
            }
        };

    }, [subscriptionId]);

    const authenticatedView = (userName: string, profilePath: string, logoutPath: string, logoutState: any) => {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>
            </NavItem>
            <NavItem>
                <NavLink replace tag={Link} className="text-dark" to={logoutPath} state={logoutState}>Logout</NavLink>
            </NavItem>
        </Fragment>);
    }

    const anonymousView = (registerPath: string, loginPath: string) => {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }

    if (!isAuthenticated) {
        const registerPath = `${ApplicationPaths.Register}`;
        const loginPath = `${ApplicationPaths.Login}`;
        return anonymousView(registerPath, loginPath);
    } else {
        const profilePath = `${ApplicationPaths.Profile}`;
        const logoutPath = `${ApplicationPaths.LogOut}`;
        const logoutState = { local: true };
        return authenticatedView(userName!, profilePath, logoutPath, logoutState);
    }
}

export default LoginMenu;