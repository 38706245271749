import { useId } from 'react';
import { useForm, SubmitHandler, Resolver, FieldErrors, FieldError } from 'react-hook-form';
import { getClassName, getErrorMessage } from './BootstrapUtils';

export class ServiceSettingsViewModel {
	static ValidateIdArray(values: string | null | undefined): FieldError | undefined {
		if (!values)
		{
		    return undefined;
		}

		const idArray: number[] = values.split(",").map(Number);
		if (idArray.some(num => Number.isNaN(num)
			|| num < 0
			|| !Number.isInteger(num)))
		{
		    return { type: "custom", message: "Invalid ID array." };
		}

		return undefined;		
	};
	isSandbox?: boolean | undefined;
	dataSource?: string | undefined;
	catalog?: string | undefined;
	aprWorkdays?: number;
	augWorkdays?: number;
	autoProcessDayOfMonth?: number | null;
	basedCodeIDsForSjRemoval: string;
	decWorkdays?: number;
	febWorkdays?: number;
	invoicePeriodFixExcludedFirmIds?: string | null;
	janWorkdays?: number;
	julWorkdays?: number;
	junWorkdays?: number;
	marWorkdays?: number;
	mayWorkdays?: number;
	novWorkdays?: number;
	octWorkdays?: number;
	sepWorkdays?: number;

	constructor(basedCodeIDsForSjRemoval?: string) {
		this.basedCodeIDsForSjRemoval = basedCodeIDsForSjRemoval ?? "";
	}
}

export const ServiceSettingsViewModelResolver: Resolver<ServiceSettingsViewModel> = async (values) => {
	const errors: FieldErrors<ServiceSettingsViewModel> = {};

	if (values.aprWorkdays || values.aprWorkdays === 0) {
		if (values.aprWorkdays > 30) {
			errors.aprWorkdays = {
				type: 'max',
				message: 'Apr. Workdays cannot exceed 30.'
			};
		}
		if (values.aprWorkdays < 1) {
			errors.aprWorkdays = {
				type: 'min',
				message: 'Apr. Workdays cannot be less than 1.'
			};
		}
	}
	if (values.augWorkdays || values.augWorkdays === 0) {
		if (values.augWorkdays > 31) {
			errors.augWorkdays = {
				type: 'max',
				message: 'Aug. Workdays cannot exceed 31.'
			};
		}
		if (values.augWorkdays < 1) {
			errors.augWorkdays = {
				type: 'min',
				message: 'Aug. Workdays cannot be less than 1.'
			};
		}
	}
	if (values.autoProcessDayOfMonth || values.autoProcessDayOfMonth === 0) {
		if (values.autoProcessDayOfMonth > 31) {
			errors.autoProcessDayOfMonth = {
				type: 'max',
				message: '"Auto Process on Day of Month" cannot exceed 31.'
			};
		}
		if (values.autoProcessDayOfMonth < 1) {
			errors.autoProcessDayOfMonth = {
				type: 'min',
				message: '"Auto Process on Day of Month" cannot be less than 1.'
			};
		}
	}
	if (!values.basedCodeIDsForSjRemoval) {
		errors.basedCodeIDsForSjRemoval = {
			type: 'required',
			message: 'BasedCodeIDsForSjRemoval is required.'
		};
	}
	if ((values.basedCodeIDsForSjRemoval?.length ?? 0) > 2000) {
		errors.basedCodeIDsForSjRemoval = {
			type: 'maxLength',
			message: 'BasedCodeIDsForSjRemoval cannot exceed 2000 characters.'
		};
	}
	errors.basedCodeIDsForSjRemoval ??= ServiceSettingsViewModel.ValidateIdArray(values.basedCodeIDsForSjRemoval);
	if (values.decWorkdays || values.decWorkdays === 0) {
		if (values.decWorkdays > 31) {
			errors.decWorkdays = {
				type: 'max',
				message: 'Dec. Workdays cannot exceed 31.'
			};
		}
		if (values.decWorkdays < 1) {
			errors.decWorkdays = {
				type: 'min',
				message: 'Dec. Workdays cannot be less than 1.'
			};
		}
	}
	if (values.febWorkdays || values.febWorkdays === 0) {
		if (values.febWorkdays > 28) {
			errors.febWorkdays = {
				type: 'max',
				message: 'Feb. Workdays cannot exceed 28.'
			};
		}
		if (values.febWorkdays < 1) {
			errors.febWorkdays = {
				type: 'min',
				message: 'Feb. Workdays cannot be less than 1.'
			};
		}
	}
	if ((values.invoicePeriodFixExcludedFirmIds?.length ?? 0) > 2000) {
		errors.invoicePeriodFixExcludedFirmIds = {
			type: 'maxLength',
			message: 'InvoicePeriodFixExcludedFirmIds cannot exceed 2000 characters.'
		};
	}
	errors.invoicePeriodFixExcludedFirmIds ??= ServiceSettingsViewModel.ValidateIdArray(values.invoicePeriodFixExcludedFirmIds);
	if (values.janWorkdays || values.janWorkdays === 0) {
		if (values.janWorkdays > 31) {
			errors.janWorkdays = {
				type: 'max',
				message: 'Jan. Workdays cannot exceed 31.'
			};
		}
		if (values.janWorkdays < 1) {
			errors.janWorkdays = {
				type: 'min',
				message: 'Jan. Workdays cannot be less than 1.'
			};
		}
	}
	if (values.julWorkdays || values.julWorkdays === 0) {
		if (values.julWorkdays > 31) {
			errors.julWorkdays = {
				type: 'max',
				message: 'Jul. Workdays cannot exceed 31.'
			};
		}
		if (values.julWorkdays < 1) {
			errors.julWorkdays = {
				type: 'min',
				message: 'Jul. Workdays cannot be less than 1.'
			};
		}
	}
	if (values.junWorkdays || values.junWorkdays === 0) {
		if (values.junWorkdays > 30) {
			errors.junWorkdays = {
				type: 'max',
				message: 'Jun. Workdays cannot exceed 30.'
			};
		}
		if (values.junWorkdays < 1) {
			errors.junWorkdays = {
				type: 'min',
				message: 'Jun.  Workdays cannot be less than 1.'
			};
		}
	}
	if (values.marWorkdays || values.marWorkdays === 0) {
		if (values.marWorkdays > 31) {
			errors.marWorkdays = {
				type: 'max',
				message: 'Mar. Workdays cannot exceed 31.'
			};
		}
		if (values.marWorkdays < 1) {
			errors.marWorkdays = {
				type: 'min',
				message: 'Mar. Workdays cannot be less than 1.'
			};
		}
	}
	if (values.mayWorkdays || values.mayWorkdays === 0) {
		if (values.mayWorkdays > 31) {
			errors.mayWorkdays = {
				type: 'max',
				message: 'May. Workdays cannot exceed 31.'
			};
		}
		if (values.mayWorkdays < 1) {
			errors.mayWorkdays = {
				type: 'min',
				message: 'May. Workdays cannot be less than 1.'
			};
		}
	}
	if (values.novWorkdays || values.novWorkdays === 0) {
		if (values.novWorkdays > 30) {
			errors.novWorkdays = {
				type: 'max',
				message: 'Nov. Workdays cannot exceed 30.'
			};
		}
		if (values.novWorkdays < 1) {
			errors.novWorkdays = {
				type: 'min',
				message: 'Nov. Workdays cannot be less than 1.'
			};
		}
	}
	if (values.octWorkdays || values.octWorkdays === 0) {
		if (values.octWorkdays > 31) {
			errors.octWorkdays = {
				type: 'max',
				message: 'Oct. Workdays cannot exceed 31.'
			};
		}
		if (values.octWorkdays < 1) {
			errors.octWorkdays = {
				type: 'min',
				message: 'Oct. Workdays cannot be less than 1.'
			};
		}
	}
	if (values.sepWorkdays || values.sepWorkdays === 0) {
		if (values.sepWorkdays > 30) {
			errors.sepWorkdays = {
				type: 'max',
				message: 'Sep. Workdays cannot exceed 30.'
			};
		}
		if (values.sepWorkdays < 1) {
			errors.sepWorkdays = {
				type: 'min',
				message: 'Sep. Workdays cannot be less than 1.'
			};
		}
	}

	return {
		values,
		errors
	};
};

export type ServiceSettingsViewModelFormData = {
	serviceSettingsViewModel?: ServiceSettingsViewModel,
	onSubmit: SubmitHandler<ServiceSettingsViewModel>
};

export const ServiceSettingsViewModelForm = (props: ServiceSettingsViewModelFormData) => {
	const formId = useId();
	const { register, handleSubmit, formState: { errors, touchedFields, isSubmitting } } = useForm<ServiceSettingsViewModel>({
		mode: "onTouched",
		resolver: ServiceSettingsViewModelResolver,
		defaultValues: props.serviceSettingsViewModel ?? new ServiceSettingsViewModel()
	});

	return <form onSubmit={handleSubmit(props.onSubmit)}>
		<div className="row mb-3">
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-autoProcessDayOfMonth"}>Auto Process on Day of Month:</label>
				<input type="number" className={getClassName(touchedFields.autoProcessDayOfMonth, errors.autoProcessDayOfMonth)} id={formId + "-autoProcessDayOfMonth"} {...register("autoProcessDayOfMonth", { valueAsNumber: true })} />
				{getErrorMessage(errors.autoProcessDayOfMonth)}
			</div>
			<div className="form-group col-md-9">
				<label htmlFor={formId + "-invoicePeriodFixExcludedFirmIds"}>Excluded Firm IDs for Invoice Period Adjustments:</label>
				<input type="text" className={getClassName(touchedFields.invoicePeriodFixExcludedFirmIds, errors.invoicePeriodFixExcludedFirmIds)} id={formId + "-invoicePeriodFixExcludedFirmIds"} {...register("invoicePeriodFixExcludedFirmIds")} />
				{getErrorMessage(errors.invoicePeriodFixExcludedFirmIds)}
			</div>
		</div>
		<div className="row mb-3">
			<div className="form-group col-md-12">
				<label htmlFor={formId + "-basedCodeIDsForSjRemoval"}>Based Code IDs of Sale Item Removal for NTE:</label>
				<input type="text" className={getClassName(touchedFields.basedCodeIDsForSjRemoval, errors.basedCodeIDsForSjRemoval)} id={formId + "-basedCodeIDsForSjRemoval"} {...register("basedCodeIDsForSjRemoval")} />
				{getErrorMessage(errors.basedCodeIDsForSjRemoval)}
			</div>
		</div>
		<div className="row mb-3">
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-janWorkdays"}>Jan. Workdays:</label>
				<input type="number" className={getClassName(touchedFields.janWorkdays, errors.janWorkdays)} id={formId + "-janWorkdays"} {...register("janWorkdays", { valueAsNumber: true })} />
				{getErrorMessage(errors.janWorkdays)}
			</div>
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-febWorkdays"}>Feb. Workdays:</label>
				<input type="number" className={getClassName(touchedFields.febWorkdays, errors.febWorkdays)} id={formId + "-febWorkdays"} {...register("febWorkdays", { valueAsNumber: true })} />
				{getErrorMessage(errors.febWorkdays)}
			</div>
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-marWorkdays"}>MarWorkdays:</label>
				<input type="number" className={getClassName(touchedFields.marWorkdays, errors.marWorkdays)} id={formId + "-marWorkdays"} {...register("marWorkdays", { valueAsNumber: true })} />
				{getErrorMessage(errors.marWorkdays)}
			</div>
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-aprWorkdays"}>AprWorkdays:</label>
				<input type="number" className={getClassName(touchedFields.aprWorkdays, errors.aprWorkdays)} id={formId + "-aprWorkdays"} {...register("aprWorkdays", { valueAsNumber: true })} />
				{getErrorMessage(errors.aprWorkdays)}
			</div>
		</div>
		<div className="row mb-3">
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-mayWorkdays"}>MayWorkdays:</label>
				<input type="number" className={getClassName(touchedFields.mayWorkdays, errors.mayWorkdays)} id={formId + "-mayWorkdays"} {...register("mayWorkdays", { valueAsNumber: true })} />
				{getErrorMessage(errors.mayWorkdays)}
			</div>
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-junWorkdays"}>JunWorkdays:</label>
				<input type="number" className={getClassName(touchedFields.junWorkdays, errors.junWorkdays)} id={formId + "-junWorkdays"} {...register("junWorkdays", { valueAsNumber: true })} />
				{getErrorMessage(errors.junWorkdays)}
			</div>
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-julWorkdays"}>JulWorkdays:</label>
				<input type="number" className={getClassName(touchedFields.julWorkdays, errors.julWorkdays)} id={formId + "-julWorkdays"} {...register("julWorkdays", { valueAsNumber: true })} />
				{getErrorMessage(errors.julWorkdays)}
			</div>
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-augWorkdays"}>AugWorkdays:</label>
				<input type="number" className={getClassName(touchedFields.augWorkdays, errors.augWorkdays)} id={formId + "-augWorkdays"} {...register("augWorkdays", { valueAsNumber: true })} />
				{getErrorMessage(errors.augWorkdays)}
			</div>
		</div>
		<div className="row mb-3">
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-sepWorkdays"}>SepWorkdays:</label>
				<input type="number" className={getClassName(touchedFields.sepWorkdays, errors.sepWorkdays)} id={formId + "-sepWorkdays"} {...register("sepWorkdays", { valueAsNumber: true })} />
				{getErrorMessage(errors.sepWorkdays)}
			</div>
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-octWorkdays"}>OctWorkdays:</label>
				<input type="number" className={getClassName(touchedFields.octWorkdays, errors.octWorkdays)} id={formId + "-octWorkdays"} {...register("octWorkdays", { valueAsNumber: true })} />
				{getErrorMessage(errors.octWorkdays)}
			</div>
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-novWorkdays"}>NovWorkdays:</label>
				<input type="number" className={getClassName(touchedFields.novWorkdays, errors.novWorkdays)} id={formId + "-novWorkdays"} {...register("novWorkdays", { valueAsNumber: true })} />
				{getErrorMessage(errors.novWorkdays)}
			</div>
			<div className="form-group col-md-3">
				<label htmlFor={formId + "-decWorkdays"}>DecWorkdays:</label>
				<input type="number" className={getClassName(touchedFields.decWorkdays, errors.decWorkdays)} id={formId + "-decWorkdays"} {...register("decWorkdays", { valueAsNumber: true })} />
				{getErrorMessage(errors.decWorkdays)}
			</div>
		</div>
		<div className="row">
			<div className="form-group col-md-12 d-flex justify-content-end">
				<button className="btn btn-primary" type="submit" disabled={isSubmitting}>Submit</button>
				<button className="btn btn-secondary mx-1" type="reset" disabled={isSubmitting}>Reset</button>
			</div>
		</div>
	</form>;
};
