import { useState } from 'react';

type FixedFeenHybridVarianceFiltersProps = {
    varianceExcludedFactIds: string;
    varianceExcludedOrgCodes: string;
    setFilter: (filter: string[]) => void;
    submittingData: boolean;
};

const validateExcludedFactIDs = (excludedFactIDs: string): boolean => {
    const excludedFirmIDArray: number[] = excludedFactIDs.split(",").map(Number);
    return !excludedFirmIDArray.some(num => Number.isNaN(num)
        || num < 0
        || !Number.isInteger(num));
}

const validateExcludedOrgCodes = (varianceExcludedFactIds: string): boolean => {
    return !varianceExcludedFactIds || varianceExcludedFactIds.length < 1024;
}

const FixedFeenHybridVarianceFilters = (props: FixedFeenHybridVarianceFiltersProps) => {
    const [varianceExcludedFactIds, setVarianceExcludedFactIds] = useState(props.varianceExcludedFactIds);
    const [varianceExcludedOrgCodes, setVarianceExcludedOrgCodes] = useState(props.varianceExcludedOrgCodes);
    const [excludedFactIdsClass, setExcludedFactIdsClass] = useState("form-control");
    const [excludedOrgCodesClass, setExcludedOrgCodesClass] = useState("form-control");
    const onExcludedFactIDsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVarianceExcludedFactIds(e.target.value);
    };
    const onExcludedOrgCodesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVarianceExcludedOrgCodes(e.target.value);
    };
    const onUpdateFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
        const isExcludedFactIDsValid = validateExcludedFactIDs(varianceExcludedFactIds);
        const isExcludedOrgCodesValid = validateExcludedOrgCodes(varianceExcludedOrgCodes);
        if (isExcludedFactIDsValid) {
            setExcludedFactIdsClass("form-control is-valid");
        } else {
            setExcludedFactIdsClass("form-control is-invalid");
        }
        if (isExcludedOrgCodesValid) {
            setExcludedOrgCodesClass("form-control is-valid");
        } else {
            setExcludedOrgCodesClass("form-control is-invalid");
        }
        if (isExcludedFactIDsValid && isExcludedOrgCodesValid) {
            props.setFilter([varianceExcludedFactIds, varianceExcludedOrgCodes]);
        }
    };

    return (<>
        <div className="row mb-3">
            <div className="form-group col-md-12">
                <label htmlFor="excludedFactIDs">Excluded Project Fact IDs:</label>
                <input type="text" className={excludedFactIdsClass} id="excludedFactIDs"
                    disabled={props.submittingData}
                    value={varianceExcludedFactIds}
                    onChange={(e) => onExcludedFactIDsChange(e)} />
            </div>
        </div>
        <div className="row mb-3">
            <div className="form-group col-md-12">
                <label htmlFor="excludedOrgCodes">Excluded Org. Codes:</label>
                <input type="text" className={excludedOrgCodesClass} id="excludedOrgCodes"
                    disabled={props.submittingData}
                    value={varianceExcludedOrgCodes}
                    onChange={(e) => onExcludedOrgCodesChange(e)} />
            </div>
        </div>
        <div className="row mb-3">
            <div className="form-group col-md-12 d-flex justify-content-end">
                <button type="submit" className="btn btn-secondary" id="updateVarianceFilters"
                    disabled={props.submittingData}
                    onClick={onUpdateFilters}>Update</button>
            </div>
        </div>
    </>);
};

export default FixedFeenHybridVarianceFilters;