import { useState, useEffect } from 'react';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';

export type BaseCode = {
    baseID: number;
    baseCode: string;
    baseName: string;
    isActive: boolean; 
    description: string | null;
    modifyDate: string;
    createDate: string;
    modifyBy: string;
    createBy: string;
};

type SaleItemBaseCodesProps = {
    baseCodeIds: string;
};

const listPageSize = 25;

const SaleItemBaseCodes = (props: SaleItemBaseCodesProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [baseCodes, setBaseCodes] = useState<BaseCode[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateBaseCodes = async () => {
            const queryParams = new URLSearchParams({
                baseCodeIds: props.baseCodeIds,
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `baseCodes?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setBaseCodes(data.codes ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setBaseCodes([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setBaseCodes([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }
        populateBaseCodes();
    }, [startIndex, props.baseCodeIds]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const renderBaseCodesTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th style={{ textAlign: 'center' }}>Active</th>
                                <th>Created by</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {baseCodes.length > 0 ? baseCodes.map(entry =>
                                <tr key={entry.baseID}>
                                    <td>{entry.baseID.toString()}</td>
                                    <td>{entry.baseCode}</td>
                                    <td>{entry.baseName}</td>
                                    <td style={{ textAlign: 'center' }}>{entry.isActive ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>}</td>
                                    <td>{entry.createBy}</td>
                                    <td>{entry.description}</td>
                                </tr>
                            ) : <tr><td colSpan={7} style={{ textAlign: 'center' }}>The base code list for filtering is empty</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for accounting periods" totalCount={totalCount}
                    itemCount={baseCodes.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="glPeriods" />
            </div>
        </>);
    }

    let contents = loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderBaseCodesTable();

    return (
        <div>
            {contents}
        </div>
    );
};

export default SaleItemBaseCodes;