import { useState } from 'react';

type SaleItemsWithMismatchingInvoicePeriodFilterProps = {
    excludedFirmIds: string;
    submittingData: boolean
    setExcludedFirmIds: (firmIDs: string) => void;
};

const validatezExcludedFirmIDs = (excludedFirmIDs: string): boolean => {
    const excludedFirmIDArray: number[] = excludedFirmIDs.split(",").map(Number);
    return !excludedFirmIDArray.some(num => Number.isNaN(num)
        || num < 0
        || !Number.isInteger(num));
}

const SaleItemsWithMismatchingInvoicePeriodFilter = (props: SaleItemsWithMismatchingInvoicePeriodFilterProps) => {
    const [excludedFirmIDs, setExcludedFirmIDs] = useState(props.excludedFirmIds);
    const [inputClass, setInputClass] = useState("form-control");
    const onExcludedFirmIDsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setExcludedFirmIDs(e.target.value);
    };
    const onUpdateExcludedFirmIDs = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (validatezExcludedFirmIDs(excludedFirmIDs)) {
            setInputClass("form-control is-valid");
            props.setExcludedFirmIds(excludedFirmIDs);
        } else {
            setInputClass("form-control is-invalid");
        }
    };

    return (<>
        <div className="row mb-3">
            <div className="form-group col-md-12">
                <label htmlFor="excludedFirmIDs">Excluded Firm IDs:</label>
                <input type="text" className={inputClass} id="excludedFirmIDs"
                    disabled={props.submittingData}
                    value={excludedFirmIDs}
                    onChange={(e) => onExcludedFirmIDsChange(e)} />
            </div>
        </div>
        <div className="row mb-3">
            <div className="form-group col-md-12 d-flex justify-content-end">
                <button type="submit" className="btn btn-secondary" id="updateExcludedFirmIDs"
                    disabled={props.submittingData}
                    onClick={onUpdateExcludedFirmIDs}>Update</button>
            </div>
        </div>
    </>);
};

export default SaleItemsWithMismatchingInvoicePeriodFilter;