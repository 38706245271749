import React, { useState, useEffect } from 'react';
import { formatDate } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';
import { GlPeriod } from '../GlPeriods';

type WipPurchasesProps = {
    period: GlPeriod;
};

type PurchaseItemView = {
    projectCode: string | null,
    projectName: string | null,
    projectPath: string | null,
    purchaseID: number;
    glAccountName: string;
    firmName: string;
    modifyDate: string;
    id: number;
    chargeType: string;
    billStatus: string;
    transDate: string | null;
    invoiceDate: string | null;
};

const buildProjectDesc = (item: PurchaseItemView): string => {
    let projectDesc = item.projectName ?? "";

    if (item.projectPath && item.projectName?.toLowerCase() !== item.projectPath?.toLowerCase()) {
        projectDesc += " ( Path: " + item.projectPath + ")";
    }

    return projectDesc;
}

const listPageSize = 25;

const WipPurchases = (props: WipPurchasesProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [purchaseItems, setPurchaseItems] = useState<PurchaseItemView[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populatePurchaseItems = async () => {
            const queryParams = new URLSearchParams({
                endDate: props.period.endDate,
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `purchaseItems?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setPurchaseItems(data.items ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setPurchaseItems([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setPurchaseItems([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }

        populatePurchaseItems();
    }, [startIndex, props.period]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const itemsByGlAccount = purchaseItems.reduce((groups, item) => {
        const groupKey = (item.glAccountName ?? '');
        if (!groups.has(groupKey)) {
            groups.set(groupKey, []);
        }
        groups.get(groupKey)?.push(item);
        return groups;
    }, new Map<string, PurchaseItemView[]>());

    const renderItemsByPurchase = (items: PurchaseItemView[]) => {
        const groupLeader = items[0];
        const groupKey = groupLeader.purchaseID.toString();

        return <React.Fragment key={groupKey}>
            <tr key={groupKey}>
                <td colSpan={6}>Purchase ID {groupKey}: {groupLeader.firmName}, {formatDate(groupLeader.modifyDate)}</td>
            </tr>
            {items.map(item => <tr key={item.id.toString()}>
                <td>{item.id.toString()}</td>
                <td title={buildProjectDesc(item)}>{item.projectCode ?? ""}</td>
                <td>{item.chargeType}</td>
                <td>{item.billStatus}</td>
                <td>{item.transDate ? formatDate(item.transDate) : ""}</td>
                <td>{item.invoiceDate ? formatDate(item.invoiceDate) : ""}</td>
            </tr>)}
        </React.Fragment>
    };

    const renderByGlAccount = (items: PurchaseItemView[]) => {
        const groupLeader = items[0];
        const groupKey = (groupLeader.glAccountName ?? '');

        const itemsByPurchase = items.reduce((groups, item) => {
            const groupKey = item.purchaseID.toString();
            if (!groups.has(groupKey)) {
                groups.set(groupKey, []);
            }
            groups.get(groupKey)?.push(item);
            return groups;
        }, new Map<string, PurchaseItemView[]>());

        return <React.Fragment key={groupKey}>
            <tr key={groupKey}>
                <td colSpan={6}>GL Account "{groupLeader.glAccountName ?? '-'}"</td>
            </tr>
            {Array.from(itemsByPurchase).map(([, itemsInGroup]) => renderItemsByPurchase(itemsInGroup))}
        </React.Fragment>
    };

    const renderPurchaseItemsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>Item ID</th>
                                <th>Project</th>
                                <th>Charge Type</th>
                                <th>Bill Status</th>
                                <th>Trans Date</th>
                                <th>Invoice Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {itemsByGlAccount.size > 0 ? Array.from(itemsByGlAccount).map(([, itemsInGroup]) => renderByGlAccount(itemsInGroup))
                                : <tr><td colSpan={6} style={{ textAlign: 'center' }}> The WIP purchase items list is empty.</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for timesheet items" totalCount={totalCount}
                    itemCount={purchaseItems.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="wipPurchaseItems" />
            </div>
        </>);
    }

    return loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderPurchaseItemsTable()
};

export default WipPurchases;