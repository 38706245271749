import { useState, useEffect } from 'react';
import { formatDateTime } from '../Utils';
import authService from './api-authorization/AuthorizeService'
import TablePager from './TablePager';

type AuditLogEntry = {
    auditProcessLogId: number;
    tableName: string | null;
    startTime: string | null;
    endTime: string | null;
    didErrorOccur: number | null;
    doesAuditTableMatchLive: boolean | null;
    insertedIntoTempTableCount: number | null;
    insertCount: number | null;
    updateCount: number | null;
    deleteCount: number | null;
};

const listPageSize = 25;

const AuditLog = (props: any) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [auditLogEntries, setAuditLogEntries] = useState<AuditLogEntry[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateAuditLogEntries = async () => {
            const queryParams = new URLSearchParams({
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `auditProcessLog?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setAuditLogEntries(data.entries ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setAuditLogEntries([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setAuditLogEntries([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }
        populateAuditLogEntries();
    }, [startIndex]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const renderAuditLogTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Table</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th style={{ textAlign: 'center' }}>Error</th>
                                <th style={{ textAlign: 'center' }}>Insertions</th>
                                <th style={{ textAlign: 'center' }}>Updates</th>
                                <th style={{ textAlign: 'center' }}>Deletions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {auditLogEntries.map(entry =>
                                <tr key={entry.auditProcessLogId}>
                                    <td>{entry.auditProcessLogId.toString()}</td>
                                    <td>{entry.tableName ?? ""}</td>
                                    <td>{entry.startTime ? formatDateTime(entry.startTime) : ""}</td>
                                    <td>{entry.endTime ? formatDateTime(entry.endTime) : ""}</td>
                                    <td className="text-black-50 text-center">{(entry.didErrorOccur ?? 0) > 0 ? <i className="text-danger bi bi-check-square"></i> : <i className="bi bi-square"></i>}</td>
                                    <td style={{ textAlign: 'center' }}>{entry.insertCount?.toString() ?? ""}</td>
                                    <td style={{ textAlign: 'center' }}>{entry.updateCount?.toString() ?? ""}</td>
                                    <td style={{ textAlign: 'center' }}>{entry.deleteCount?.toString() ?? ""}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for audit log entries" totalCount={totalCount}
                    itemCount={auditLogEntries.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="auditLog" />
            </div>
        </>);
    }

    let contents = loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderAuditLogTable();

    return (
        <div>
            <h1 id="tableLabel">Audit Process Log</h1>
            <p>The following are all the audit process log entries in Clearview.</p>
            {contents}
        </div>
    );
};

export default AuditLog;