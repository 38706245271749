import { useState } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';

type ReportLinksProps = {
    period: GlPeriodViewModel;
};

const ReportLinks = (props: ReportLinksProps) => {
    const [statusText, setStatusText] = useState("");
    const [loadingAllTransactionDetails, setLoadingAllTransactionDetails] = useState(false);
    const [loadingClosedFFProjectProfits, setLoadingClosedFFProjectProfits] = useState(false);
    const [loadingFFVarianceAnalysis, setLoadingFFVarianceAnalysis] = useState(false);
    const [loadingRevDistribution, setLoadingRevDistribution] = useState(false);
    const [loadingUtilization, setLoadingUtilization] = useState(false);
    const [loadingWIPBackupData, setLoadingWIPBackupData] = useState(false);
    const [loadingWIPWriteoff, setLoadingWIPWriteoff] = useState(false);
    const [loadingWipWriteoffAllTransactions, setLoadingWipWriteoffAllTransactions] = useState(false);

    const downloadReport = async (e: React.MouseEvent<HTMLAnchorElement>, loading: boolean, setLoading: (b: boolean) => void) => {
        e.preventDefault();

        if (loading) {
            return false;
        }

        setStatusText('');
        setLoading(true);

        const clickedElement = e.target as HTMLAnchorElement;
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(clickedElement.getAttribute('href')!, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            const reader = response.body!.getReader();
            const chunks: Uint8Array[] = [];
            while (true) {

                const result = await Promise.race([
                    reader.read(),
                    new Promise<{ done: boolean, value?: undefined }>((_, reject) =>
                        setTimeout(() => reject(new Error('File download timeout')), 180000)  // 3 minutes timeout
                    )
                ]);
                
                if (result.done) {
                    break;
                }

                if (result.value) {
                    chunks.push(result.value);
                }
            }

            const fileBlob = new Blob(chunks);
            console.log(fileBlob);

            const url = window.URL.createObjectURL(fileBlob);

            // Create a temporary link element
            const a = document.createElement('a');
            a.href = url;
            a.download = clickedElement.download; // Specify the desired file name
            document.body.appendChild(a);

            // Trigger the click event to start the download
            a.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            setStatusText("");
        } catch (error) {
            console.error('Error downloading file:', error);
            const errorString = error instanceof Error ? error.message : `Error downloading file "${clickedElement.download}"`;
            setStatusText(errorString);
        }

        setLoading(false);

        return false;
    };

    const downloadAllTransactionDetails = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        await downloadReport(e, loadingAllTransactionDetails, setLoadingAllTransactionDetails);
    };

    const downloadClosedFFProjectProfits = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        await downloadReport(e, loadingClosedFFProjectProfits, setLoadingClosedFFProjectProfits);
    };

    const downloadFFVarianceAnalysis = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        await downloadReport(e, loadingFFVarianceAnalysis, setLoadingFFVarianceAnalysis);
    };

    const downloadRevDistribution = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        await downloadReport(e, loadingRevDistribution, setLoadingRevDistribution);
    };

    const downloadUtilization = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        await downloadReport(e, loadingUtilization, setLoadingUtilization);
    };

    const downloadWIPBackupData = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        await downloadReport(e, loadingWIPBackupData, setLoadingWIPBackupData);
    };

    const downloadWIPWriteoff = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        await downloadReport(e, loadingWIPWriteoff, setLoadingWIPWriteoff);
    };

    const downloadWipWriteoffAllTransactions = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        await downloadReport(e, loadingWipWriteoffAllTransactions, setLoadingWipWriteoffAllTransactions);
    };

    const renderReportUrls = () => {
        return <>
            {statusText ? <div className="row"><div className="col-12"><div className="alert alert-danger" role="alert">
                <div>{statusText}</div>
            </div></div></div> : <></>}
            <dl className="row">
                <dt>All Transaction Details</dt><dd><a href={"/datarails/TransactionDetails?glPeriod=" + props.period.periodCode} download={props.period.periodCode + " All Transaction Details.csv"} onClick={downloadAllTransactionDetails}>{props.period.periodCode + " All Transaction Details.csv"}</a> <div className={"spinner-border spinner-border-sm" + (loadingAllTransactionDetails ? "" : " d-none")} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div></dd>
                <dt>Closed Fixed-Fee Project Profits</dt><dd><a href={"/datarails/ClosedProjectProfits?glPeriod=" + props.period.periodCode} download={props.period.periodCode + " Closed Fixed-Fee Project Profits.csv"} onClick={downloadClosedFFProjectProfits}>{props.period.periodCode + " Closed Fixed-Fee Project Profits.csv"}</a> <div className={"spinner-border spinner-border-sm" + (loadingClosedFFProjectProfits ? "" : " d-none")} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div></dd>
                <dt>Fixed-Fee Variance Analysis</dt><dd><a href={"/datarails/FixedFeeVariances?startPeriod=" + props.period.previousPeriod?.periodId + "&endPeriod=" + props.period.periodId + "&currentPeriodCode=" + props.period.periodCode} download={props.period.periodCode + " Fixed-Fee Variance Analysis.csv"} onClick={downloadFFVarianceAnalysis}>{props.period.periodCode + " Fixed-Fee Variance Analysis.csv"}</a> <div className={"spinner-border spinner-border-sm" + (loadingFFVarianceAnalysis ? "" : " d-none")} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div></dd>
                <dt>Rev. Distribution</dt><dd><a href={"/datarails/RevDistribution?glPeriod=" + props.period.periodCode} download={props.period.periodCode + " Revenue Distributions.csv"} onClick={downloadRevDistribution}>{props.period.periodCode + " Revenue Distributions.csv"}</a> <div className={"spinner-border spinner-border-sm" + (loadingRevDistribution ? "" : " d-none")} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div></dd>
                <dt>Rev. Rec. Utilization Analysis</dt><dd><a href={"/datarails/Utilizations?glPeriod=" + props.period.periodCode} download={props.period.periodCode + " Utilization Analysis.csv"} onClick={downloadUtilization}>{props.period.periodCode + " Utilization Analysis.csv"}</a> <div className={"spinner-border spinner-border-sm" + (loadingUtilization ? "" : " d-none")} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div></dd>
                <dt>WIP Backup Data</dt><dd><a href={"/datarails/WipBackupData?glPeriod=" + props.period.periodCode} download={props.period.periodCode + " WIP Backup Data.csv"} onClick={downloadWIPBackupData}>{props.period.periodCode + " WIP Backup Data.csv"}</a> <div className={"spinner-border spinner-border-sm" + (loadingWIPBackupData ? "" : " d-none")} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div></dd>
                <dt>WIP Write off</dt><dd><a href={"/datarails/WipWriteOff?glPeriod=" + props.period.periodCode} download={props.period.periodCode + " WIP Write-off.csv"} onClick={downloadWIPWriteoff}>{props.period.periodCode + " WIP Write-off.csv"}</a> <div className={"spinner-border spinner-border-sm" + (loadingWIPWriteoff ? "" : " d-none")} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div></dd>
                <dt>Wip Writeoff - All Transactions</dt><dd><a href={"/datarails/WipWriteOffsForAllTrans?startPeriod=" + props.period.previousPeriod?.periodId + "&endPeriod=" + props.period.periodId + "&currentPeriodCode=" + props.period.periodCode} download={props.period.periodCode + " WIP Write-offs (All Transactions).csv"} onClick={downloadWipWriteoffAllTransactions}>{props.period.periodCode + " WIP Write-offs (All Transactions).csv"}</a> <div className={"spinner-border spinner-border-sm" + (loadingWipWriteoffAllTransactions ? "" : " d-none")} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div></dd>
            </dl>
        </>;
    };

    return (<div id="glPeriodDetailsLayout" className="container pt-3">
        {renderReportUrls()}
    </div>
    );
};

export default ReportLinks;