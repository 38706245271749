import { useState, useEffect } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import IncompleteBillReviewFilters from './IncompleteBillReviewFilters';
import IncompleteBillReviewsList from './IncompleteBillReviewsList';
import IncompleteBillReviewPCs from './IncompleteBillReviewPCs';
import IncompleteBillReviewItems from './IncompleteBillReviewItems';
import { addDays } from '../../Utils';
import { GlPeriodViewModel, IncompleteBillReviewsRemoval } from './GlPeriodDetailsLayout';
import authService from '../api-authorization/AuthorizeService'

export type BillReview = {
    billReviewID: number;
    projectID: number;
    projectName: string;
    periodID: number;
    createDate: string;
    isPMReviewed: boolean;
    isPAReviewed: boolean;
    modifyBy: string;
};

type IncompleteBillReviewsProps = {
    period: GlPeriodViewModel;
    refreshData: () => void;
};

const getDefaultCutoffDate = (period: GlPeriodViewModel) => {
    if (period.incompleteBillReviewCutOff) {
        return new Date(period.incompleteBillReviewCutOff);
    }
    const date = new Date(period.endDate);
    return addDays(date, -15);
}

const IncompleteBillReviews = (props: IncompleteBillReviewsProps) => {
    const [submittingData, setSubmittingData] = useState(false);
    const [statusText, setStatusText] = useState("");
    const [cutoffDate, setCutoffDate] = useState(getDefaultCutoffDate(props.period));
    const [openSections, setOpenSections] = useState(["ReviewFilters", "ReviewsList", "ReviewItems", "ReviewPCs"]);
    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    useEffect(() => {
        const compareDates = (cutoffDate: Date, incompleteBillReviewCutOff: string | null): boolean =>{
            if (!incompleteBillReviewCutOff) {
                return false;
            }
            return cutoffDate.getTime() === (new Date(incompleteBillReviewCutOff)).getTime();
        }
        const updateIncompleteBillReviewCutOff = async () => {
            setSubmittingData(true);

            const url = `glPeriodFacts/${props.period.factId!.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                method: 'PATCH',
                headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
                body: JSON.stringify({
                    incompleteBillReviewCutOff: cutoffDate
                })
            });
            if (response.ok) {
                try {
                    await response.json();
                    setStatusText("");
                    props.refreshData();
                } catch (error) {
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setStatusText(await response.text());
            }
            setSubmittingData(false);
        }
        if (props.period.factId && !compareDates(cutoffDate, props.period.incompleteBillReviewCutOff)) {
            updateIncompleteBillReviewCutOff();
        }
    }, [cutoffDate, props, props.period.incompleteBillReviewCutOff, props.period.factId, props.refreshData]);

    return (<div className="container">
        {statusText ? <div className="alert alert-danger" role="alert">
            <div>{statusText}</div>
        </div> : <></>}
        {
            //@ts-ignore
            <Accordion open={openSections} toggle={setOpenSection}>
                <AccordionItem>
                    <AccordionHeader targetId="ReviewFilters">Incomplete Bill-Review Filters</AccordionHeader>
                    <AccordionBody accordionId="ReviewFilters">
                        <IncompleteBillReviewFilters period={props.period} cutoffDate={cutoffDate} setCutoffDate={setCutoffDate}
                            submittingData={submittingData} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="ReviewsList">Incomplete Bill-Reviews</AccordionHeader>
                    <AccordionBody accordionId="ReviewsList">
                        <IncompleteBillReviewsList cutoffDate={cutoffDate} version={props.period.flags & IncompleteBillReviewsRemoval} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="ReviewItems">Incomplete Bill-Review Items</AccordionHeader>
                    <AccordionBody accordionId="ReviewItems">
                        <IncompleteBillReviewItems cutoffDate={cutoffDate} version={props.period.flags & IncompleteBillReviewsRemoval} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="ReviewPCs">Incomplete Bill-Review Percent-Complete</AccordionHeader>
                    <AccordionBody accordionId="ReviewPCs">
                        <IncompleteBillReviewPCs cutoffDate={cutoffDate} version={props.period.flags & IncompleteBillReviewsRemoval} />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        }
    </div>);
};

export default IncompleteBillReviews;