import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import GlPeriodDetailsLayout from './GlPeriodDetailsLayout';

interface IGlPeriodDetailsPage {
    currentGlPeriodId?: string;
    setCurrentGlPeriodId?: (periodId?: string) => void;
};

export const GlPeriodContext = React.createContext<IGlPeriodDetailsPage>({});

const GlPeriodDetails = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const periodId = searchParams.get('periodId') ?? undefined;
    const [currentGlPeriodId, setCurrentGlPeriodId] = useState(periodId);
    if (periodId !== currentGlPeriodId) {
        setCurrentGlPeriodId(periodId);
    }
    return <GlPeriodContext.Provider value={{ currentGlPeriodId, setCurrentGlPeriodId }}>
        {currentGlPeriodId ? <GlPeriodDetailsLayout periodId={currentGlPeriodId} /> : <></>}
    </GlPeriodContext.Provider>
}

export default GlPeriodDetails;