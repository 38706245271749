export const ApplicationName = 'FACS';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message'
};

export const LogoutActions = {
    LogoutCallback: '/',
    Logout: 'Account/Logout',
    LoggedOut: '/'
};

export const LoginActions = {
    Login: 'Account/Login',
    LoginCallback: '/',
    LoginFailed: 'Account/Login',
    Profile: 'profile',
    Register: 'Account/Register'
};

const prefix = '/Identity';

export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: `_configuration/${ApplicationName}`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${LogoutActions.LoggedOut}`,
    LogOutCallback: `${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: 'Identity/Account/Register',
    IdentityManagePath: 'Identity/Account/Manage',
    GetAccessToken: `Identity/Authorize/AccessToken`
};
