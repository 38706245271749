import { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import AuditOverallPopulationHistory from './AuditOverallPopulationHistory';
import AuditOverallPopulationJob from './AuditOverallPopulationJob';

type AuditOverallPopulationStatusProps = {
    version: number;
};

const AuditOverallPopulationStatus = (props: AuditOverallPopulationStatusProps) => {
    const [openSections, setOpenSections] = useState(["JobInfo", "JobHistory"]);
    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    return (<div className="container">
        {
            //@ts-ignore
            <Accordion open={openSections} toggle={setOpenSection}>
                <AccordionItem>
                    <AccordionHeader targetId="JobInfo">Audit-Overall-Population Job Information</AccordionHeader>
                    <AccordionBody accordionId="JobInfo">
                        <AuditOverallPopulationJob />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="JobHistory">Audit-Overall-Population Job History</AccordionHeader>
                    <AccordionBody accordionId="JobHistory">
                        <AuditOverallPopulationHistory version={props.version} />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        }
    </div>);
};

export default AuditOverallPopulationStatus;