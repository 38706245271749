import { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { GlPeriodViewModel, RemoveSaleItemsForNTE } from './GlPeriodDetailsLayout';
import SaleItemsForNteFixList from './SaleItemsForNteFixList';

type SaleItemsForNteFixProps = {
    period: GlPeriodViewModel;
    refreshData: () => void;
};

const SaleItemsForNteFix = (props: SaleItemsForNteFixProps) => {
    const [openSections, setOpenSections] = useState(["SalesItems"]);
    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    return (<div className="container">
        {
            //@ts-ignore
            <Accordion open={openSections} toggle={setOpenSection}>
                <AccordionItem>
                    <AccordionHeader targetId="SalesItems">Sales Items to Be Deleted</AccordionHeader>
                    <AccordionBody accordionId="SalesItems">
                        <SaleItemsForNteFixList periodId={props.period.periodId} version={props.period.flags & RemoveSaleItemsForNTE } />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        }
    </div>);
};

export default SaleItemsForNteFix;