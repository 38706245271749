import { useState, useEffect } from 'react';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';

export type Firm = {
    firmID: number;
    firmName: string;
    firmCode: string;
    parentFirmID: number | null;
    mainAddressID: number | null;
    modifyDate: string;
    modifyBy: string;
    createBy: string;
    createDate: string;
    webSite: string | null;
    firmNote: string | null;
    aRNote: string | null;
};

type SaleItemsWithMismatchingInvoicePeriodFilterDetailsProps = {
    excludedFirmIds: string;
};

const listPageSize = 25;

const SaleItemsWithMismatchingInvoicePeriodFilterDetails = (props: SaleItemsWithMismatchingInvoicePeriodFilterDetailsProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [firms, setFirms] = useState<Firm[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateFirms = async () => {
            const queryParams = new URLSearchParams({
                firmIds: props.excludedFirmIds,
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `firms?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setFirms(data.firms ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setFirms([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setFirms([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }
        populateFirms();
    }, [startIndex, props.excludedFirmIds]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const renderFirmsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Created by</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {firms.length > 0 ? firms.map(firm =>
                                <tr key={firm.firmID}>
                                    <td>{firm.firmID.toString()}</td>
                                    <td>{firm.firmCode}</td>
                                    <td>{firm.firmName}</td>
                                    <td>{firm.createBy}</td>
                                    <td>{firm.firmNote}</td>
                                </tr>
                            ) : <tr><td colSpan={5} style={{ textAlign: 'center' }}>The excluded firms list for filtering is empty</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for accounting periods" totalCount={totalCount}
                    itemCount={firms.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="glPeriods" />
            </div>
        </>);
    }

    let contents = loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderFirmsTable();

    return (
        <div>
            {contents}
        </div>
    );
};

export default SaleItemsWithMismatchingInvoicePeriodFilterDetails;