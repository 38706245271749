import './GlPeriodDetailsLayout.css';
import { useState, useEffect, useRef } from 'react';
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService'
import { GlPeriod } from '../GlPeriods';
import GlPeriodHeader from './GlPeriodHeader';
import GlPeriodDetailTabs from './GlPeriodDetailTabs';
import ConfirmationDlg, { ConfirmationParams } from '../ConfirmationDlg';
import MessageDlg, { MessageDlgParams } from '../MessageDlg';

type GlPeridDetailsLayoutProps = {
    periodId: string;
};

export interface GlPeriodViewModel extends GlPeriod {
    factId: number | null,
    incompleteBillReviewCutOff: string | null;
    incompleteBillReviewCount: number | null;
    timesheetItemCountWithMismatchOrgUnits: number | null;
    basedCodeIDsForSjRemoval: string;
    salesJournalEntryCountByBaseCodes: number | null;
    salesJournalEntryCountForNteFix: number | null;
    invoicePeriodFixAdjustmentId: number | null;
    invoicePeriodFixExcludedFirmIds: string;
    expenseGjId: number | null;
    wipAdjustmentId: number | null;
    billedFixedFeeAdjustmentId: number | null;
    billedHybridAdjustmentId: number | null;
    billedTnMandNTEAdjustmentId: number | null;
    fixedFeeVarianceAdjustmentId: number | null;
    varianceExcludedFactIds: string;
    varianceExcludedOrgCodes: string;
    closeStartedAt: string | null;
    closedAt: string | null;
    closedBy: string | null;
    workDays: number;
    flags: number;
    previousPeriod: GlPeriod | null;
    previousPeriodWipGjId: number | null;
    previousPeriodWipReversalGjId: number | null;
};

const ProcessingFlag = 1;
export const AuditOverallPopulationJobFlag = 2;
const IntializedForProcessing = ProcessingFlag | AuditOverallPopulationJobFlag;
export const IncompleteBillReviewsRemoval = 4;
const PreviousPeriodWipsReversal = 8;
export const UpdateTimesheetFlag = 16;
export const RemoveSaleItemsByBaseCodes = 32;
export const RemoveSaleItemsForNTE = 64;
const CreateAdjForInvoicePeriodMismatch = 128;
const ExpenseDistributionJournalCreation = 256;
const RevRecForWIP = 512;
const BilledFixedFee = 1024;
const BilledHybrid = 2048;
const BilledTnMandNTE = 4096;
const FixedFeeHybridVarianceFlag = 8192;
const ProcessedFlag = 16384;
const AdjustmentFlags = CreateAdjForInvoicePeriodMismatch
    | ExpenseDistributionJournalCreation
    | RevRecForWIP
    | BilledFixedFee
    | BilledHybrid
    | BilledTnMandNTE
    | FixedFeeHybridVarianceFlag;

export const AllActionsFlags = AdjustmentFlags
    | AuditOverallPopulationJobFlag
    | IncompleteBillReviewsRemoval
    | PreviousPeriodWipsReversal
    | UpdateTimesheetFlag
    | RemoveSaleItemsByBaseCodes
    | RemoveSaleItemsForNTE;

const FirstPeriod = "2008-01";
const StartProcessingMessagePrefix = "<p>Click the \"OK\" button will mark the displayed accounting period as closed, enable adjustments for the same accounting period, run all the steps from the beginning until ";
const StepRunningMessage = "<p>We are waiting for the SQL script execution to finish. Click the \"OK\" button will trigger another run of the same script.</p>";
const StepRunningWarning = "<p>We are waiting for the SQL script execution to finish. Click the \"OK\" button will trigger another run of the same script, and <span class=\"text-danger\">there is a risk that a duplicate may be created if both runs succeed.<span></p>";

type StepParameters = {
    sectionId: string;
    title: string;
    checkIfCompleted: () => boolean;
    startProcessingMessage: string;
    refreshFlag: number;
    isRunningMessage: string;
    runMessage: string;
    showAbortMessage?: boolean | undefined;
    abortMessage?: string | undefined;
    onProceed: () => void;
    canBeOutOfSequence: boolean;
};

type SetterFunctions = {
    setProcessingUserAction: (b: boolean) => void;
    setAlertMessageParams: (m: MessageDlgParams) => void;
    setDataVersion: (version: number) => void;
    setRefreshDataFlags: (flags: number) => void;
    setStatusText: (t: string) => void;
};

const processErrorText = (taskName: string, error: any): string => {
    console.error('An error occurred:', error);
    
    if (error instanceof Error) {
        return `Error communicating with server to ${taskName}. ${error.message}`;
    }

    return `Error communicating with server to ${taskName}. The cause is unknown. Additional information may be found in the javascript console output.`;
};

const getFailedResponseText = async (taskName: string, response: Response): Promise<string> => {
    let responseText: string;
    try {
        responseText = await response.text();
    } catch (error) {
        console.error('An error occurred:', error);
        responseText = "Unable to retrieve further details.";
    }

    return `Error communicating with server to ${taskName}. Status ${response.status} ${response.statusText}. ${responseText}`;
};

const onProceedWithReports = async (setters: SetterFunctions, glPeriodId: number) => {
    setters.setProcessingUserAction(true);

    const url = `glPeriods/${glPeriodId.toString()}/close`;
    const token = await authService.getAccessToken();
    const response = await fetch(url, {
        method: 'POST',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    if (response.ok) {
        try {
            const data = await response.json();

            if (data.hasErrors) {
                if (data.message) {
                    const msgDlgParams: MessageDlgParams = {
                        title: "Closing Reports",
                        message: data.message
                    };
                    setters.setAlertMessageParams(msgDlgParams);
                } else {
                    const msgDlgParams: MessageDlgParams = {
                        title: "Closing Reports",
                        message: "An unknown error occurred while completing the closing process."
                    };
                    setters.setAlertMessageParams(msgDlgParams);
                }
            } else {
                if (data.message) {
                    const msgDlgParams: MessageDlgParams = {
                        title: "Closing Reports",
                        message: data.message
                    };
                    setters.setAlertMessageParams(msgDlgParams);
                }
                setters.setRefreshDataFlags(ProcessedFlag);
            }
            setters.setStatusText("");
        } catch (error) {
            setters.setStatusText(processErrorText("complete the closing process", error));
        }
    }
    else {
        setters.setStatusText(await getFailedResponseText("complete the closing process", response));
    }

    setters.setProcessingUserAction(false);
};

const onProceedWithCreateAdjustment = async (setters: SetterFunctions, glPeriodId: number,
    action: string, flag: number, taskName: string) => {
    setters.setProcessingUserAction(true);

    const url = `adjustments/${action}`;
    const token = await authService.getAccessToken();
    const response = await fetch(url, {
        method: 'POST',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify({
            periodId: glPeriodId
        })
    });

    if (response.ok) {
        setters.setRefreshDataFlags(flag);
    } else {
        setters.setStatusText(await getFailedResponseText(taskName, response));
    }

    setters.setProcessingUserAction(false);
}

const onProceedWithRemoveIncompleteBillReviews = async (setters: SetterFunctions, glPeriodId: number, incompleteBillReviewCutOff: string) => {
    setters.setProcessingUserAction(true);

    const queryParams = new URLSearchParams({
        periodId: glPeriodId.toString(),
        cutOffDate: incompleteBillReviewCutOff,
        modActionFilter: 'Action%'
    });
    const url = `billReviews?${queryParams.toString()}`;
    const token = await authService.getAccessToken();
    const response = await fetch(url, {
        method: 'DELETE',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    })

    if (response.ok) {
        setters.setRefreshDataFlags(IncompleteBillReviewsRemoval);
    } else {
        setters.setStatusText(await getFailedResponseText("delete incomplete bill reviews", response));
    }

    setters.setProcessingUserAction(false);
};

const onProceedWithReversePrevWIPs = async (setters: SetterFunctions, glPeriodId: number, previousPeriodCode: string) => {
    setters.setProcessingUserAction(true);

    const url = `adjustments/reversePrevPeriodWips`;
    const token = await authService.getAccessToken();
    const response = await fetch(url, {
        method: 'POST',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify({
            periodId: glPeriodId,
            previousPeriodCode: previousPeriodCode
        })
    });

    if (response.ok) {
        setters.setRefreshDataFlags(PreviousPeriodWipsReversal);
    } else {
        setters.setStatusText(await getFailedResponseText(`reverse WIP for ${previousPeriodCode}`, response));
    }

    setters.setProcessingUserAction(false);
};

const onProceedWithUpdateTimesheets = async (setters: SetterFunctions, glPeriodId: number, periodEndDate: string) => {
    setters.setProcessingUserAction(true);

    const url = `timesheets/updateOrgUnits`;
    const token = await authService.getAccessToken();
    const response = await fetch(url, {
        method: 'PATCH',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify({
            periodId: glPeriodId,
            periodEndDate: periodEndDate
        })
    });

    if (response.ok) {
        setters.setRefreshDataFlags(UpdateTimesheetFlag);
    } else {
        setters.setStatusText(await getFailedResponseText("update org units on timesheets", response));
    }

    setters.setProcessingUserAction(false);
};

const onProceedWithFixByBaseCodes = async (setters: SetterFunctions, glPeriodId: number, basedCodeIDsForSjRemoval: string) => {
    setters.setProcessingUserAction(true);

    const url = `saleItems/removeByBaseCodes?periodId=${glPeriodId}&baseCodeIds=${basedCodeIDsForSjRemoval}`;
    const token = await authService.getAccessToken();
    const response = await fetch(url, {
        method: 'DELETE',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" }
    });

    if (response.ok) {
        setters.setRefreshDataFlags(RemoveSaleItemsByBaseCodes);
    } else {
        setters.setStatusText(await getFailedResponseText("delete sales items by basecodes", response));
    }

    setters.setProcessingUserAction(false);
};

const onProceedWithFixForNte = async (setters: SetterFunctions, glPeriodId: number) => {
    setters.setProcessingUserAction(true);

    const url = `saleItems/removeForNteFix?periodId=${glPeriodId}`;
    const token = await authService.getAccessToken();
    const response = await fetch(url, {
        method: 'DELETE',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" }
    });

    if (response.ok) {
        setters.setRefreshDataFlags(RemoveSaleItemsForNTE);
    } else {
        setters.setStatusText(await getFailedResponseText("delete sales items to fix for NTE", response));
    }

    setters.setProcessingUserAction(false);
};

const onProceedWithFixMismatchingInvoicePeriods = async (setters: SetterFunctions, glPeriodId: number,
    invoicePeriodFixExcludedFirmIds: string | null) => {
    setters.setProcessingUserAction(true);

    const url = `adjustments/createAdjustmentForInvoicePeriodMismatch`;
    const token = await authService.getAccessToken();
    const response = await fetch(url, {
        method: 'POST',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify({
            periodId: glPeriodId,
            invoicePeriodFixExcludedFirmIds: invoicePeriodFixExcludedFirmIds
        })
    });

    if (response.ok) {
        setters.setRefreshDataFlags(CreateAdjForInvoicePeriodMismatch);
    } else {
        setters.setStatusText(await getFailedResponseText("create adjustment for invoice period mismatch", response));
    }

    setters.setProcessingUserAction(false);
};

const GlPeridDetailsLayout = (props: GlPeridDetailsLayoutProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [alertMessageParams, setAlertMessageParams] = useState<MessageDlgParams | null>(null);
    const [loading, setLoading] = useState(true);
    const [refreshData, setRefreshData] = useState(0);
    const [processingUserAction, setProcessingUserAction] = useState(false);
    const [glPeriod, setGlPeriod] = useState<GlPeriodViewModel | null>(null);
    const [glPeriodFlags, setGlPeriodFlags] = useState(0);
    const [glPeriodId, setGlPeriodId] = useState(0);
    const [glPeriodCode, setGlPeriodCode] = useState('');
    const [incompleteBillReviewCutOff, setIncompleteBillReviewCutOff] = useState('');
    const [previousPeriodCode, setPreviousPeriodCode] = useState("");
    const [refreshDataFlags, setRefreshDataFlags] = useState(0);
    const [runUntilFlag, setRunUntilFlag] = useState(0);
    const [taskStatusPollingTimer, setTaskStatusPollingTimer] = useState<NodeJS.Timer | null>(null);
    const [isConfirmationDlgOpen, setIsConfirmationDlgOpen] = useState(false);
    const [confirmationParams, setConfirmationParams] = useState<ConfirmationParams | null>(null);
    const [activeSectionId, setActiveSectionId] = useState('auditOverallPopulation');

    const autoProcessingFlag = useRef(0);

    // Refresh GL Period details
    useEffect(() => {
        const populateGlPeriodDetails = async () => {
            const url = `glPeriods/${props.periodId.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setGlPeriod(data);
                    setGlPeriodFlags(data.flags);
                    setGlPeriodId(data.periodId);
                    setGlPeriodCode(data.periodCode);
                    setIncompleteBillReviewCutOff(data.incompleteBillReviewCutOff ?? '');
                    setPreviousPeriodCode(data.previousPeriod?.periodCode ?? "");
                    setStatusText("");
                } catch (error) {
                    setGlPeriod(null);
                    setGlPeriodFlags(0);
                    setGlPeriodId(0);
                    setGlPeriodCode('');
                    setIncompleteBillReviewCutOff('');
                    setPreviousPeriodCode("");
                    setStatusText(processErrorText("populate period details", error));
                }
            }
            else {
                setGlPeriod(null);
                setGlPeriodFlags(0);
                setGlPeriodId(0);
                setGlPeriodCode('');
                setIncompleteBillReviewCutOff('');
                setPreviousPeriodCode("");
                setStatusText(await getFailedResponseText("populate period details", response));
            }
            setLoading(false);
        }
        populateGlPeriodDetails();
    }, [props.periodId, refreshData]);

    // Refresh AuditOverallPopulation Status
    useEffect(() => {
        const checkAuditOverallPopulationStatus = async () => {
            const queryParams = new URLSearchParams({
                jobName: "z_AuditOverallPopulation",
                startTime: glPeriod?.closeStartedAt ?? "",
                periodFactId: glPeriod?.factId?.toString() ?? "",
            });
            const url = `inFocusJobLog/latest?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    if (data && data.didRun && !data.hadError) {
                        setRefreshDataFlags(0);
                        setRefreshData((new Date()).getTime());
                    }
                    setStatusText("");
                } catch (error) {
                    setStatusText(processErrorText("get z_AuditOverallPopulation job status", error));
                }
            }
            else {
                setStatusText(await getFailedResponseText("get z_AuditOverallPopulation job status", response));
            }
        }

        if ((refreshDataFlags & AuditOverallPopulationJobFlag) && (glPeriodFlags & refreshDataFlags) === 0) {
            if (!taskStatusPollingTimer) {
                const intervalId = setInterval(checkAuditOverallPopulationStatus, 5000);
                setTaskStatusPollingTimer(intervalId);
            }
        } else if (!refreshDataFlags && taskStatusPollingTimer) {
            clearInterval(taskStatusPollingTimer);
            setTaskStatusPollingTimer(null);
        }
    }, [glPeriod?.factId, glPeriod?.closeStartedAt, glPeriodFlags, refreshData,
        refreshDataFlags, taskStatusPollingTimer]);

    // Refresh incomplete bill reviews
    useEffect(() => {
        const checkRemoveIncompleteBillReviewsStatus = async () => {
            const queryParams = new URLSearchParams({
                cutoffTime: glPeriod?.incompleteBillReviewCutOff!,
                modActionFilter: 'Action%',
                glPeriodFactId: glPeriod?.factId?.toString() ?? '',
            });
            const url = `billReviews/Incompletes?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    if (data.totalCount === 0) {
                        setRefreshDataFlags(0);
                        setRefreshData((new Date()).getTime());
                    }
                } catch (error) {
                    setStatusText(processErrorText("remove incomplete bill reviews", error));
                }
            }
            else {
                setStatusText(await getFailedResponseText("remove incomplete bill reviews", response));
            }
        }

        if ((refreshDataFlags & IncompleteBillReviewsRemoval) && (glPeriodFlags & refreshDataFlags) === 0) {
            if (!taskStatusPollingTimer) {
                const intervalId = setInterval(checkRemoveIncompleteBillReviewsStatus, 5000);
                setTaskStatusPollingTimer(intervalId);
            }
        } else if (!refreshDataFlags && taskStatusPollingTimer) {
            clearInterval(taskStatusPollingTimer);
            setTaskStatusPollingTimer(null);
        }
    }, [glPeriodFlags, glPeriod?.factId, glPeriod?.incompleteBillReviewCutOff,
        refreshData, refreshDataFlags, taskStatusPollingTimer]);

    // Refresh previous period Wip reversal
    useEffect(() => {
        const checkReversePrevWIPsStatus = async () => {
            const prevPeriodCode = glPeriod?.previousPeriod?.periodCode ?? '';
            if (prevPeriodCode === '') {
                if (glPeriod?.periodCode === FirstPeriod) {
                    setRefreshDataFlags(0);
                    setRefreshData((new Date()).getTime());
                    return;
                }
            }
            const queryParams = new URLSearchParams({
                periodCode: prevPeriodCode,
                flags: PreviousPeriodWipsReversal.toString()
            });
            const url = `adjustments/check?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    if (data.totalCount > 0) {
                        setRefreshDataFlags(0);
                        setRefreshData((new Date()).getTime());
                    }
                } catch (error) {
                    setStatusText(processErrorText(`reverse WIP of ${prevPeriodCode}`, error));
                }
            }
            else {
                setStatusText(await await getFailedResponseText("reverse previous period WIP", response));
            }
        }

        if ((refreshDataFlags & PreviousPeriodWipsReversal) && (glPeriodFlags & refreshDataFlags) === 0) {
            if (!taskStatusPollingTimer) {
                const intervalId = setInterval(checkReversePrevWIPsStatus, 5000);
                setTaskStatusPollingTimer(intervalId);
            }
        } else if (!refreshDataFlags && taskStatusPollingTimer) {
            clearInterval(taskStatusPollingTimer);
            setTaskStatusPollingTimer(null);
        }
    }, [glPeriodFlags, glPeriod?.periodCode, glPeriod?.previousPeriod?.periodCode,
        refreshData, refreshDataFlags, taskStatusPollingTimer]);

    // Refresh timesheet org units
    useEffect(() => {
        const checkUpdateTimesheetOrgUnitsStatus = async () => {
            const queryParams = new URLSearchParams({
                glPeriodFactId: glPeriod?.factId?.toString() ?? "",
                endDate: glPeriod?.endDate ?? "",
            });
            const url = `timesheetItems/UpdateOrgUnitResults?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    if (data.totalCount === 0) {
                        setRefreshDataFlags(0);
                        setRefreshData((new Date()).getTime());
                    }
                } catch (error) {
                    setStatusText(processErrorText("update org. units on timesheets", error));
                }
            }
            else {
                setStatusText(await getFailedResponseText("update org. units on timesheets", response));
            }
        }

        if ((refreshDataFlags & UpdateTimesheetFlag) && (glPeriodFlags & refreshDataFlags) === 0) {
            if (!taskStatusPollingTimer) {
                const intervalId = setInterval(checkUpdateTimesheetOrgUnitsStatus, 5000);
                setTaskStatusPollingTimer(intervalId);
            }
        } else if (!refreshDataFlags && taskStatusPollingTimer) {
            clearInterval(taskStatusPollingTimer);
            setTaskStatusPollingTimer(null);
        }
    }, [glPeriodFlags, glPeriod?.endDate, glPeriod?.factId,
        refreshData, refreshDataFlags, taskStatusPollingTimer]);

    // Refresh results for removing saleitems by basecodes
    useEffect(() => {
        const checkRemoveSaleItemsByBaseCodesStatus = async () => {
            const queryParams = new URLSearchParams({
                periodId: glPeriodId.toString(),
                glPeriodFactId: glPeriod?.factId?.toString() ?? "",
                baseCodeIds: glPeriod?.basedCodeIDsForSjRemoval ?? ""
            });
            const url = `saleItems/removeSaleItemsByBaseCodesResults?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    if (data.totalCount === 0) {
                        setRefreshDataFlags(0);
                        setRefreshData((new Date()).getTime());
                    }
                } catch (error) {
                    setStatusText(processErrorText("remove sale items by base codes", error));
                }
            }
            else {
                setStatusText(await getFailedResponseText("remove sale items by base codes", response));
            }
        }

        if ((refreshDataFlags & RemoveSaleItemsByBaseCodes) && (glPeriodFlags & refreshDataFlags) === 0) {
            if (!taskStatusPollingTimer) {
                const intervalId = setInterval(checkRemoveSaleItemsByBaseCodesStatus, 5000);
                setTaskStatusPollingTimer(intervalId);
            }
        } else if (!refreshDataFlags && taskStatusPollingTimer) {
            clearInterval(taskStatusPollingTimer);
            setTaskStatusPollingTimer(null);
        }
    }, [glPeriodFlags, glPeriodId, glPeriod?.factId, glPeriod?.basedCodeIDsForSjRemoval,
        refreshData, refreshDataFlags, taskStatusPollingTimer]);

    // Refresh results for removing saleitems for NTE fix
    useEffect(() => {
        const checkRemoveSaleItemsForNteFixStatus = async () => {
            const queryParams = new URLSearchParams({
                periodId: glPeriodId.toString(),
                glPeriodFactId: glPeriod?.factId?.toString() ?? "",
                baseCodeIds: glPeriod?.basedCodeIDsForSjRemoval ?? ""
            });
            const url = `saleItems/removeSaleItemsForNteResults?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    if (data.totalCount === 0) {
                        setRefreshDataFlags(0);
                        setRefreshData((new Date()).getTime());
                    }
                } catch (error) {
                    setStatusText(processErrorText("remove sale items for NTE", error));
                }
            }
            else {
                setStatusText(await getFailedResponseText("remove sale items for NTE", response));
            }
        }

        if ((refreshDataFlags & RemoveSaleItemsForNTE) && (glPeriodFlags & refreshDataFlags) === 0) {
            if (!taskStatusPollingTimer) {
                const intervalId = setInterval(checkRemoveSaleItemsForNteFixStatus, 5000);
                setTaskStatusPollingTimer(intervalId);
            }
        } else if (!refreshDataFlags && taskStatusPollingTimer) {
            clearInterval(taskStatusPollingTimer);
            setTaskStatusPollingTimer(null);
        }
    }, [glPeriodFlags, glPeriodId, glPeriod?.factId, glPeriod?.basedCodeIDsForSjRemoval,
        refreshData, refreshDataFlags, taskStatusPollingTimer]);

    // Refresh results of adjustment 
    useEffect(() => {
        const checkAdjustment = async () => {
            const queryParams = new URLSearchParams({
                periodCode: glPeriodCode,
                flags: (refreshDataFlags & AdjustmentFlags).toString()
            });
            const url = `adjustments/check?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    if (data.totalCount > 0) {
                        setRefreshDataFlags(0);
                        setRefreshData((new Date()).getTime());
                    }
                } catch (error) {
                    setStatusText(processErrorText("check adjustment creation result", error));
                }
            }
            else {
                setStatusText(await getFailedResponseText("check adjustment creation result", response));
            }
        }

        if ((refreshDataFlags & AdjustmentFlags) && (glPeriodFlags & refreshDataFlags) === 0) {
            if (!taskStatusPollingTimer) {
                const intervalId = setInterval(checkAdjustment, 5000);
                setTaskStatusPollingTimer(intervalId);
            }
        } else if (!refreshDataFlags && taskStatusPollingTimer) {
            clearInterval(taskStatusPollingTimer);
            setTaskStatusPollingTimer(null);
        }
    }, [glPeriodFlags, glPeriodCode, refreshData, refreshDataFlags,
        taskStatusPollingTimer]);

    // Refresh results for process reports
    useEffect(() => {
        const checkProcessReportStatus = async () => {
            const url = `glPeriodFacts/${glPeriod?.factId?.toString() ?? ""}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    if ((data.flags & ProcessedFlag) === ProcessedFlag) {
                        setRefreshDataFlags(0);
                        setRefreshData((new Date()).getTime());
                    }
                } catch (error) {
                    setStatusText(processErrorText("create reports", error));
                }
            }
            else {
                setStatusText(await getFailedResponseText("create reports", response));
            }
        }

        if ((refreshDataFlags & ProcessedFlag) && (glPeriodFlags & refreshDataFlags) === 0) {
            if (!taskStatusPollingTimer) {
                const intervalId = setInterval(checkProcessReportStatus, 5000);
                setTaskStatusPollingTimer(intervalId);
            }
        } else if (!refreshDataFlags && taskStatusPollingTimer) {
            clearInterval(taskStatusPollingTimer);
            setTaskStatusPollingTimer(null);
        }
    }, [glPeriodFlags, glPeriod?.factId, refreshData, refreshDataFlags, taskStatusPollingTimer]);

    // Auto-processing
    useEffect(() => {
        const updateAutoProcessingFlag = (): boolean => {
            // Start from AuditOverallPopulationJobFlag
            if (!autoProcessingFlag.current) {
                autoProcessingFlag.current = AuditOverallPopulationJobFlag;
            }

            let currnentAutoProcessingFlag = autoProcessingFlag.current;

            // Skip over completed steps
            while (currnentAutoProcessingFlag & glPeriodFlags) {
                if (currnentAutoProcessingFlag < runUntilFlag) {
                    currnentAutoProcessingFlag = (currnentAutoProcessingFlag << 1);
                } else {
                    setRunUntilFlag(0);
                    autoProcessingFlag.current = 0;
                    return false;
                }
            }

            if (currnentAutoProcessingFlag !== autoProcessingFlag.current) {
                autoProcessingFlag.current = currnentAutoProcessingFlag;
                return true;
            }

            // Still on the same step. Do nothing.
            return false;
        };

        console.log(`refreshDataFlags: ${refreshDataFlags.toString(16)}`);

        if ((glPeriodFlags & IntializedForProcessing) !== IntializedForProcessing
            || refreshDataFlags) {
            return;
        }

        console.log(`glPeriodFlags: ${glPeriodFlags.toString(16)}, runUntilFlag: ${runUntilFlag.toString(16)}, autoProcessingFlag: ${autoProcessingFlag.current.toString(16)}`);

        if (!runUntilFlag) {
            if (autoProcessingFlag.current) {
                autoProcessingFlag.current = 0;
            }
            return;
        }

        console.log(`autoProcessingFlag.current: ${autoProcessingFlag.current.toString(16)}`);

        if (!updateAutoProcessingFlag()) {
            return;
        };

        console.log(`Updated autoProcessingFlag.current: ${autoProcessingFlag.current.toString(16)}`);

        const autoProcessingSetters: SetterFunctions = {
            setProcessingUserAction: setProcessingUserAction,
            setAlertMessageParams: setAlertMessageParams,
            setDataVersion: setRefreshData,
            setRefreshDataFlags: setRefreshDataFlags,
            setStatusText: setStatusText
        };

        switch (autoProcessingFlag.current) {
            case IncompleteBillReviewsRemoval:
                onProceedWithRemoveIncompleteBillReviews(autoProcessingSetters, glPeriodId, incompleteBillReviewCutOff);
                break;

            case PreviousPeriodWipsReversal:
                onProceedWithReversePrevWIPs(autoProcessingSetters, glPeriodId, previousPeriodCode);
                break;

            case UpdateTimesheetFlag:
                onProceedWithUpdateTimesheets(autoProcessingSetters, glPeriodId, glPeriod?.endDate ?? '');
                break;

            case RemoveSaleItemsByBaseCodes:
                onProceedWithFixByBaseCodes(autoProcessingSetters, glPeriodId, glPeriod?.basedCodeIDsForSjRemoval ?? '');
                break;

            case RemoveSaleItemsForNTE:
                onProceedWithFixForNte(autoProcessingSetters, glPeriodId);
                break;

            case CreateAdjForInvoicePeriodMismatch:
                onProceedWithFixMismatchingInvoicePeriods(autoProcessingSetters, glPeriodId, glPeriod?.invoicePeriodFixExcludedFirmIds ?? null);
                break;

            case ExpenseDistributionJournalCreation:
                onProceedWithCreateAdjustment(autoProcessingSetters, glPeriodId, "createAdjustmentForExpenses",
                    ExpenseDistributionJournalCreation, "create expense distribution journal entry");
                break;

            case RevRecForWIP:
                onProceedWithCreateAdjustment(autoProcessingSetters, glPeriodId, "createAdjustmentForWIP", RevRecForWIP,
                    "create adjustment for WIP");
                break;

            case BilledFixedFee:
                onProceedWithCreateAdjustment(autoProcessingSetters, glPeriodId, "createAdjustmentForBilledFixedFee",
                    BilledFixedFee, "create adjustment for billed fixed fee");
                break;

            case BilledHybrid:
                onProceedWithCreateAdjustment(autoProcessingSetters, glPeriodId, "createAdjustmentForBilledHybrid",
                    BilledHybrid, "create adjustment for billed hybrid");
                break;

            case BilledTnMandNTE:
                onProceedWithCreateAdjustment(autoProcessingSetters, glPeriodId, "createAdjustmentForBilledTnMandNTE",
                    BilledTnMandNTE, "create adjustment for billed T&M/NTE");
                break;

            case FixedFeeHybridVarianceFlag:
                onProceedWithCreateAdjustment(autoProcessingSetters, glPeriodId, "createAdjustmentForFixedFeeHybridVariance",
                    FixedFeeHybridVarianceFlag, "create adjustment for fixed fee/hybrid closed project variance");
                break;

            case ProcessedFlag:
                onProceedWithReports(autoProcessingSetters, glPeriodId);
                break;
        }
    }, [glPeriodFlags, glPeriodId, incompleteBillReviewCutOff, previousPeriodCode,
        glPeriod?.endDate, glPeriod?.basedCodeIDsForSjRemoval,
        glPeriod?.invoicePeriodFixExcludedFirmIds,
        refreshDataFlags, runUntilFlag])

    const isProcessing = (glPeriodFlags & IntializedForProcessing) === IntializedForProcessing;

    const setters: SetterFunctions = {
        setProcessingUserAction: setProcessingUserAction,
        setAlertMessageParams: setAlertMessageParams,
        setDataVersion: setRefreshData,
        setRefreshDataFlags: setRefreshDataFlags,
        setStatusText: setStatusText
    };

    const getActiveSectionId = () => activeSectionId;

    const onRunStep = async (step: StepParameters) => {
        setActiveSectionId(step.sectionId);

        if (step.checkIfCompleted()) {
            const msgDlgParams: MessageDlgParams = {
                title: step.title,
                message: "This step is already completed."
            };
            setAlertMessageParams(msgDlgParams);
            return;
        }

        let confirmMessage: string;

        if (!isProcessing) {
            confirmMessage = step.startProcessingMessage;
        } else if (refreshDataFlags & step.refreshFlag) {
            confirmMessage = step.isRunningMessage;
        } else if (step.showAbortMessage) {
            confirmMessage = step.abortMessage!;
        } else {
            confirmMessage = step.runMessage;
        }

        confirmMessage += " Proceed?";

        const confirmationParams: ConfirmationParams = {
            message: confirmMessage,
            setResult: async (result: boolean) => {
                if (!result) {
                    return;
                }

                if (!isProcessing) {
                    setRunUntilFlag(step.refreshFlag);
                    await onProceedWithPrepareClosing();
                } else {
                    if (step.canBeOutOfSequence || runUntilFlag === step.refreshFlag) {
                        await step.onProceed();
                    } else {
                        setRunUntilFlag(step.refreshFlag);
                    }
                }
            },
        };

        setConfirmationParams(confirmationParams);
        setIsConfirmationDlgOpen(true);
    };

    const runPrepareClosingMsg = "<p>\"Prepare Closing\" will mark the displayed accounting period as closed, enable adjustments for the same accounting period, and then start the Clearview job \"z_AuditOverallPopulation\".</p>"
        + (glPeriod?.closedAt ? "<p>Please note that a month-end closing process was completed on " + (glPeriod?.closedAt)
        + (glPeriod?.closedBy ? " by " + glPeriod?.closedBy : "") + " for this accounting period. </p>"
        : "");

    const onProceedWithPrepareClosing = async () => {
        setProcessingUserAction(true);

        const url = `glPeriods/${props.periodId.toString()}/prepareClosing`;
        const token = await authService.getAccessToken();
        const response = await fetch(url, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        if (response.ok) {
            try {
                const data = await response.json();

                if (data.hasErrors) {
                    if (data.message) {
                        const msgDlgParams: MessageDlgParams = {
                            title: "Prepare Closing",
                            message: data.message
                        };
                        setAlertMessageParams(msgDlgParams);
                    } else {
                        const msgDlgParams: MessageDlgParams = {
                            title: "Prepare Closing",
                            message: "An unknown error occurred while preparing for closing."
                        };
                        setAlertMessageParams(msgDlgParams);
                    }
                } else {
                    setGlPeriod(data);
                    setGlPeriodFlags(data.flags);
                    setGlPeriodId(data.periodId);
                    setGlPeriodCode(data.periodCode);
                    setIncompleteBillReviewCutOff(data.incompleteBillReviewCutOff ?? '');
                    setPreviousPeriodCode(data.previousPeriod?.periodCode ?? "");
                    setRefreshDataFlags(AuditOverallPopulationJobFlag); // Wait for the Clearview job to finish
                }

                setStatusText("");
            } catch (error) {
                setGlPeriod(null);
                setGlPeriodFlags(0);
                setGlPeriodId(0);
                setGlPeriodCode('');
                setIncompleteBillReviewCutOff('');
                setPreviousPeriodCode("");
                setStatusText(processErrorText("prepare for closing", error));
            }
        }
        else {
            setGlPeriod(null);
            setGlPeriodFlags(0);
            setGlPeriodId(0);
            setGlPeriodCode('');
            setIncompleteBillReviewCutOff('');
            setPreviousPeriodCode("");
            setStatusText(await getFailedResponseText("prepare for closing", response));
        }

        setProcessingUserAction(false);
    };

    const prepareClosingStepPrams: StepParameters = {
        sectionId: 'auditOverallPopulation',
        title: "Prepare Closing",
        checkIfCompleted: () => (glPeriodFlags & AuditOverallPopulationJobFlag) === AuditOverallPopulationJobFlag,
        startProcessingMessage: runPrepareClosingMsg,
        refreshFlag: AuditOverallPopulationJobFlag,
        isRunningMessage: "<p>We are waiting for the Clearview job \"z_AuditOverallPopulation\" to finish. Click the \"OK\" button will trigger another run of the same job.</p>",
        runMessage: runPrepareClosingMsg,
        onProceed: onProceedWithPrepareClosing,
        canBeOutOfSequence: false
    };

    const removeIncompleteBillReviewsStepParams: StepParameters = {
        sectionId: "incompleteBillReviews",
        title: "Remove Incomplete Bill Reviews",
        checkIfCompleted: () => (glPeriodFlags & IncompleteBillReviewsRemoval) === IncompleteBillReviewsRemoval,
        startProcessingMessage: StartProcessingMessagePrefix + "until all the incomplete bill reviews along with their items and percent-completes are permanently deleted.</p>",
        refreshFlag: IncompleteBillReviewsRemoval,
        isRunningMessage: StepRunningMessage,
        runMessage: "<p>\"Remove Incomplete Bill-Reviews\" will permanently delete the incomplete bill reviews along with their items and percent-completes as shown.</p>",
        onProceed: async () => await onProceedWithRemoveIncompleteBillReviews(setters, glPeriodId, incompleteBillReviewCutOff),
        canBeOutOfSequence: false
    };

    const reversePrevWIPsStepParams: StepParameters = {
        sectionId: "previousPeriodWips",
        title: "Reverse WIP of Previous Period",
        checkIfCompleted: () => (glPeriodFlags & PreviousPeriodWipsReversal) === PreviousPeriodWipsReversal,
        startProcessingMessage: StartProcessingMessagePrefix + "an adjustment is created that reverses the WIP general journal entry for \""
            + glPeriod?.previousPeriod?.periodCode + "\".</p>",
        refreshFlag: PreviousPeriodWipsReversal,
        isRunningMessage: StepRunningWarning,
        runMessage: "<p>\"Reverse WIP of Previous Period\" will create a General Journal entry that reverses the Revenue Recognition of WIP from \""
            + glPeriod?.previousPeriod?.periodCode + "\".</p>",
        onProceed: async () => await onProceedWithReversePrevWIPs(setters, glPeriodId, previousPeriodCode),
        canBeOutOfSequence: true
    };

    const updateTimesheetsStepParams: StepParameters = {
        sectionId: "orgUnitsOnTimesheets",
        title: "Update Org.-Units on Timesheets",
        checkIfCompleted: () => (glPeriodFlags & UpdateTimesheetFlag) === UpdateTimesheetFlag,
        startProcessingMessage: StartProcessingMessagePrefix + "the \"Home Org.-Unit\" and \"Charge Org.-Unit\" on the displayed timesheet items are set to the employee's Org. Unit.</p>",
        refreshFlag: UpdateTimesheetFlag,
        isRunningMessage: StepRunningMessage,
        runMessage: "<p>\"Update Org.-Units on Timesheets\" will set the \"Home Org.-Unit\" and \"Charge Org.-Unit\" on the displayed timesheet items to the employee's Org. Unit.</p>",
        onProceed: async () => await onProceedWithUpdateTimesheets(setters, glPeriodId, glPeriod?.endDate ?? ''),
        canBeOutOfSequence: true
    };

    const removeSaleItemsByBasedCodesStepParams: StepParameters = {
        sectionId: "removeSalesItemsByBaseCodes",
        title: "Remove Sale Items by Base Codes",
        checkIfCompleted: () => (glPeriodFlags & RemoveSaleItemsByBaseCodes) === RemoveSaleItemsByBaseCodes,
        startProcessingMessage: StartProcessingMessagePrefix + "the displayed sale items filtered by their GL account base codes as shown are removed.</p>",
        refreshFlag: RemoveSaleItemsByBaseCodes,
        isRunningMessage: StepRunningMessage,
        runMessage: "<p>\"Remove Sale Items by Base Codes\" will remove the displayed sale items, filtered by their GL account base codes as shown.</p>",
        onProceed: async () => await onProceedWithFixByBaseCodes(setters, glPeriodId, glPeriod?.basedCodeIDsForSjRemoval ?? ''),
        canBeOutOfSequence: true
    };

    const removeSaleItemsForNteFixStepParams: StepParameters = {
        sectionId: "removeSalesItemsForNte",
        title: "Remove Sale Items for NTE Fix",
        checkIfCompleted: () => (glPeriodFlags & RemoveSaleItemsForNTE) === RemoveSaleItemsForNTE,
        startProcessingMessage: StartProcessingMessagePrefix + "the displayed sale items filtered by their GL account base codes and amounts as shown are removed.</p>",
        refreshFlag: RemoveSaleItemsForNTE,
        isRunningMessage: StepRunningMessage,
        runMessage: "<p>\"Remove Sale Items for NTE Fix\" will remove the displayed sale items, filtered by their GL account base codes and amounts as shown.</p>",
        onProceed: async () => await onProceedWithFixForNte(setters, glPeriodId),
        canBeOutOfSequence: true
    };

    const fixMismatchingInvoicePeriodsStepParams: StepParameters = {
        sectionId: "periodMismatches",
        title: "Adjustment for Sales Journal Entries w/ Invoice Period Mismatch",
        checkIfCompleted: () => (glPeriodFlags & CreateAdjForInvoicePeriodMismatch) === CreateAdjForInvoicePeriodMismatch,
        startProcessingMessage: StartProcessingMessagePrefix + "a new General Journal entry is created with line items for each correction of invoice period mismatch.</p>",
        refreshFlag: CreateAdjForInvoicePeriodMismatch,
        isRunningMessage: StepRunningWarning,
        runMessage: "<p>\"Adjustment for Sales Journal Entries w/ Invoice Period Mismatch\" will create a new General Journal entry, with line items for each correction.</p>",
        onProceed: async () => await onProceedWithFixMismatchingInvoicePeriods(setters, glPeriodId, glPeriod?.invoicePeriodFixExcludedFirmIds ?? null),
        canBeOutOfSequence: true
    };

    const createExpenseDistributionJournalEntryStepParams: StepParameters = {
        sectionId: "expenseDistribution",
        title: "Create Expense Distribution Journal Entry",
        checkIfCompleted: () => (glPeriodFlags & ExpenseDistributionJournalCreation) === ExpenseDistributionJournalCreation,
        startProcessingMessage: StartProcessingMessagePrefix + "a new General Journal entry is created for expense distribution.</p>",
        refreshFlag: ExpenseDistributionJournalCreation,
        isRunningMessage: StepRunningWarning,
        runMessage: "<p>\"Create Expense Distribution Journal Entry\" will create a new General Journal entry for expense distribution.</p>",
        onProceed: async () => await onProceedWithCreateAdjustment(setters, glPeriodId, "createAdjustmentForExpenses",
            ExpenseDistributionJournalCreation, "create expense distribution journal entry"),
        canBeOutOfSequence: true
    };

    const createRevRecForWipStepParams: StepParameters = {
        sectionId: "wip",
        title: "Create Rev. Rec for WIP",
        checkIfCompleted: () => (glPeriodFlags & RevRecForWIP) === RevRecForWIP,
        startProcessingMessage: StartProcessingMessagePrefix + "a new General Journal entry is created for WIP.</p>",
        refreshFlag: RevRecForWIP,
        isRunningMessage: StepRunningWarning,
        runMessage: "<p>\"Create Rev. Rec for WIP\" will create a new General Journal entry, with line items from each section aggregated by org. units. Purchase items are aggregated by GL account base codes as well.</p>",
        onProceed: async () => await onProceedWithCreateAdjustment(setters, glPeriodId, "createAdjustmentForWIP",
            RevRecForWIP, "create adjustment for WIP"),
        canBeOutOfSequence: true
    }

    const billedFixedFeeStepParams: StepParameters = {
        sectionId: "billedFixedFee",
        title: "Create Rev. Rec for Billed Fixed Fee",
        checkIfCompleted: () => (glPeriodFlags & BilledFixedFee) === BilledFixedFee,
        startProcessingMessage: StartProcessingMessagePrefix + "a new General Journal entry is created for Rev. Rec of Billed Fixed Fee.</p>",
        refreshFlag: BilledFixedFee,
        isRunningMessage: StepRunningWarning,
        runMessage: "<p>\"Rev. Rec for Billed Fixed Fee\" will create a new General Journal entry, with line items from each section aggregated by project and the related sale items' charge org. units and home org.units. Purchase items are aggregated by GL account base codes and org. units as well.</p>",
        onProceed: async () => await onProceedWithCreateAdjustment(setters, glPeriodId, "createAdjustmentForBilledFixedFee",
            BilledFixedFee, "create adjustment for billed fixed fee"),
        canBeOutOfSequence: true
    }

    const billedHybridStepParams: StepParameters = {
        sectionId: "billedHybrid",
        title: "Create Rev. Rec for Billed Hybrid",
        checkIfCompleted: () => (glPeriodFlags & BilledHybrid) === BilledHybrid,
        startProcessingMessage: StartProcessingMessagePrefix + "a new General Journal entry is created for Rev. Rec of Billed Hybrid.</p>",
        refreshFlag: BilledHybrid,
        isRunningMessage: StepRunningWarning,
        runMessage: "<p>\"Rev. Rec for Billed Hybrid\" will create a new General Journal entry, with line items from each section aggregated by project and the related sale items' charge org. units and home org.units.</p>",
        onProceed: async () => await onProceedWithCreateAdjustment(setters, glPeriodId, "createAdjustmentForBilledHybrid",
            BilledHybrid, "create adjustment for billed hybrid"),
        canBeOutOfSequence: true
    }

    const billedTnMandNteStepParams: StepParameters = {
        sectionId: 'billedTnM_NTE',
        title: "Rev. Rec for Billed T&M/NTE",
        checkIfCompleted: () => (glPeriodFlags & BilledTnMandNTE) === BilledTnMandNTE,
        startProcessingMessage: StartProcessingMessagePrefix + "a new General Journal entry is created for Rev. Rec of Billed T&M/NTE.</p>",
        refreshFlag: BilledTnMandNTE,
        isRunningMessage: StepRunningWarning,
        runMessage: "<p>\"Rev. Rec for Billed T&M/NTE\" will create a new General Journal entry, with line items from each section aggregated by project and the related sale items' charge org. units and home org.units.</p>",
        onProceed: async () => await onProceedWithCreateAdjustment(setters, glPeriodId, "createAdjustmentForBilledTnMandNTE",
            BilledTnMandNTE, "create adjustment for billed T&M/NTE"),
        canBeOutOfSequence: true
    };

    const varianceStepParams: StepParameters = {
        sectionId: 'fixedFeenHybridVariance',
        title: "Fixed Fee/Hybrid Variance",
        checkIfCompleted: () => (glPeriodFlags & FixedFeeHybridVarianceFlag) === FixedFeeHybridVarianceFlag,
        startProcessingMessage: StartProcessingMessagePrefix + "a new General Journal entry is created for Rev. Rec of Fixed Fee/Hybrid Variance.</p>",
        refreshFlag: FixedFeeHybridVarianceFlag,
        isRunningMessage: StepRunningWarning,
        runMessage: "<p>\"Rev. Rec for Fixed Fee/Hybrid Variance\" will create a new General Journal entry, with line items from each section aggregated by project.</p>",
        onProceed: async () => await onProceedWithCreateAdjustment(setters, glPeriodId, "createAdjustmentForFixedFeeHybridVariance",
            FixedFeeHybridVarianceFlag, "create adjustment for fixed fee/hybrid closed project variance"),
        canBeOutOfSequence: false
    };

    const reportsStepParams: StepParameters = {
        sectionId: 'reports',
        title: "Closing Reports",
        checkIfCompleted: () => (glPeriodFlags & AllActionsFlags) === AllActionsFlags
            && (glPeriodFlags & (ProcessingFlag | ProcessedFlag)) === ProcessedFlag,
        startProcessingMessage: "<p>Click the \"OK\" button will mark the displayed accounting period as closed, enable adjustments for the same accounting period,, run all the steps from the beginning, generate reports for this closing process, and then mark the process as completed.</p>",
        refreshFlag: ProcessedFlag,
        isRunningMessage: StepRunningMessage,
        runMessage: "<p>This will turn off \"Allow Adjustment\" for this period, generate reports for this closing process, and mark the process as completed.</p>",
        showAbortMessage: (glPeriodFlags & AllActionsFlags) !== AllActionsFlags,
        abortMessage: "<p>This will turn off \"Allow Adjustment\" for this period, abort the unfinished tasks, and mark the process as completed.</p>",
        onProceed: async () => await onProceedWithReports(setters, glPeriodId),
        canBeOutOfSequence: false
    };

    const onPrepareClosing = async () => {
        await onRunStep(prepareClosingStepPrams);
    };

    const onRemoveIncompleteBillReviews = async () => {
        await onRunStep(removeIncompleteBillReviewsStepParams);
    };

    const onReversePrevWIPs = async () => {
        await onRunStep(reversePrevWIPsStepParams);
    };

    const onUpdateTimesheet = async () => {
        await onRunStep(updateTimesheetsStepParams);
    };

    const onRemoveSalesItemsByBaseCodes = async () => {
        await onRunStep(removeSaleItemsByBasedCodesStepParams);
    };

    const onRemoveSalesItemsForNteFix = async () => {
        await onRunStep(removeSaleItemsForNteFixStepParams);
    };

    const onFixMismatchingInvoicePeriods = async () => {
        await onRunStep(fixMismatchingInvoicePeriodsStepParams);
    };

    const onCreateExpenseDistributionJournalEntry = async () => {
        await onRunStep(createExpenseDistributionJournalEntryStepParams);
    };

    const onCreateRevRecForWip = async () => {
        await onRunStep(createRevRecForWipStepParams);
    };

    const onBilledFixedFee = async () => {
        await onRunStep(billedFixedFeeStepParams);
    };

    const onBilledHybrid = async () => {
        await onRunStep(billedHybridStepParams);
    };

    const onBilledTnMandNte = async () => {
        await onRunStep(billedTnMandNteStepParams);
    };

    const onVariance = async () => {
        await onRunStep(varianceStepParams);
    };

    const onReports = async () => {
        await onRunStep(reportsStepParams);
    };

    const setIncompleteBillReviewClass = (): string => {
        if (glPeriodFlags & IncompleteBillReviewsRemoval) {
            return "completed";
        }

        if (refreshDataFlags & IncompleteBillReviewsRemoval) {
            return "processing";
        }

        if (glPeriod?.incompleteBillReviewCount === 0) {
            return "all-done";
        }

        return "";
    };

    const setPreviousPeriodWipClass = (): string => {
        if (glPeriodFlags & PreviousPeriodWipsReversal) {
            return "completed";
        }

        if (refreshDataFlags & PreviousPeriodWipsReversal) {
            return "processing";
        }

        if (glPeriod?.previousPeriodWipReversalGjId !== 0) {
            return "all-done";
        }

        return "";
    };

    const setOrgUnitsTimesheetClass = (): string => {
        if (glPeriodFlags & UpdateTimesheetFlag) {
            return "completed";
        }

        if (refreshDataFlags & UpdateTimesheetFlag) {
            return "processing";
        }

        if ((glPeriod?.timesheetItemCountWithMismatchOrgUnits ?? 0) !== 0) {
            return "all-done";
        }

        return "";
    };

    const setRemoveSalesItemsByBaseCodesClass = (): string => {
        if (glPeriodFlags & RemoveSaleItemsByBaseCodes) {
            return "completed";
        }

        if (refreshDataFlags & RemoveSaleItemsByBaseCodes) {
            return "processing";
        }

        if ((glPeriod?.salesJournalEntryCountByBaseCodes ?? 0) === 0) {
            return "all-done";
        }

        return "";
    };

    const setRemoveSalesItemsForNteFixClass = (): string => {
        if (glPeriodFlags & RemoveSaleItemsForNTE) {
            return "completed";
        }

        if (refreshDataFlags & RemoveSaleItemsForNTE) {
            return "processing";
        }

        if ((glPeriod?.salesJournalEntryCountForNteFix ?? 0) === 0) {
            return "all-done";
        }

        return "";
    };

    const setAdjustmentClass = (flag: number, id: number | null | undefined): string => {
        if (glPeriodFlags & flag) {
            return "completed";
        }

        if (refreshDataFlags & flag) {
            return "processing";
        }

        if ((id ?? 0) !== 0) {
            return "all-done";
        }

        return "";
    };

    const setReportsClass = (): string => {
        if ((glPeriodFlags & (AllActionsFlags | ProcessedFlag)) === (AllActionsFlags | ProcessedFlag) && isProcessing) {
            return "completed";
        }

        if (refreshDataFlags & ProcessedFlag) {
            return "processing";
        }

        if ((glPeriodFlags & AllActionsFlags) === AllActionsFlags) {
            return "all-done";
        }

        return "";
    };

    const renderGlPeriodDetailsLayout = () => {
        return <CardBody>
            <div className="col-12 d-flex align-items-start">
                <div className="tab-content flex-fill" id="workflow-tab-contents">
                    <div className="tab-pane fade show active" id="glPeriodDetails" role="tabpanel">{glPeriod ? <GlPeriodDetailTabs
                        glPeriod={glPeriod}
                        refreshGlPeriodData={() => setRefreshData((new Date()).getTime())}
                        getActiveSectionId={getActiveSectionId}
                        setActiveSectionId={setActiveSectionId} /> : <></>}</div>
                </div>
                <Nav className="nav flex-column nav-pills ms-auto d-none d-md-block mw-25" id="workflow-tabs" role="tablist" aria-orientation="vertical">
                    <NavItem>
                        <NavLink tag="button" id="workflow-prepare-closing-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "auditOverallPopulation"}
                            className={(glPeriodFlags & AuditOverallPopulationJobFlag) === AuditOverallPopulationJobFlag ? "completed"
                                : ((glPeriodFlags & IntializedForProcessing) === ProcessingFlag ? "processing" : "")}
                            active={activeSectionId === "auditOverallPopulation"}
                            onClick={onPrepareClosing}
                            disabled={processingUserAction}>Prepare for Closing <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-del-incompletes-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "incompleteBillReviews"}
                            className={setIncompleteBillReviewClass()}
                            active={isProcessing && activeSectionId === "incompleteBillReviews"}
                            onClick={onRemoveIncompleteBillReviews}
                            disabled={processingUserAction}>Remove Incomplete Bill Reviews <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-reverse-prev-wips-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "previousPeriodWips"}
                            className={setPreviousPeriodWipClass()}
                            active={isProcessing && activeSectionId === "previousPeriodWips"}
                            onClick={onReversePrevWIPs}
                            disabled={processingUserAction || !!glPeriod?.previousPeriodWipReversalGjId}>Reverse WIP of Previous Period <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-update-timesheet-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "orgUnitsOnTimesheets"}
                            className={setOrgUnitsTimesheetClass()}
                            active={isProcessing && activeSectionId === "orgUnitsOnTimesheets"}
                            onClick={() => onUpdateTimesheet()}
                            disabled={processingUserAction}>Update Org.-Units on Timesheets <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-remove-saleitems-by-bascodes-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "removeSalesItemsByBaseCodes"}
                            className={setRemoveSalesItemsByBaseCodesClass()}
                            active={isProcessing && activeSectionId === "removeSalesItemsByBaseCodes"}
                            onClick={() => onRemoveSalesItemsByBaseCodes()}
                            disabled={processingUserAction}>Remove Sale Items by Base Codes <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-remove-saleitems-for-nte-fix-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "removeSalesItemsForNte"}
                            className={setRemoveSalesItemsForNteFixClass()}
                            active={isProcessing && activeSectionId === "removeSalesItemsForNte"}
                            onClick={() => onRemoveSalesItemsForNteFix()}
                            disabled={processingUserAction}>Remove Sale Items for NTE Fix <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-fix-mismatching-invoice-periods-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "periodMismatches"}
                            className={setAdjustmentClass(CreateAdjForInvoicePeriodMismatch, glPeriod?.invoicePeriodFixAdjustmentId)}
                            active={isProcessing && activeSectionId === "periodMismatches"}
                            onClick={() => onFixMismatchingInvoicePeriods()}
                            disabled={processingUserAction || !!glPeriod?.invoicePeriodFixAdjustmentId}>Create Journal Entry for Sale Items w/ Invoice Period Mismatch <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-expense-distribution-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "expenseDistribution"}
                            className={setAdjustmentClass(ExpenseDistributionJournalCreation, glPeriod?.expenseGjId)}
                            active={isProcessing && activeSectionId === "expenseDistribution"}
                            onClick={() => onCreateExpenseDistributionJournalEntry()}
                            disabled={processingUserAction || !!glPeriod?.expenseGjId}>Create Expense Distribution Journal Entry <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-create-rev-rec-for-wip-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "wip"}
                            active={isProcessing && activeSectionId === "wip"}
                            className={setAdjustmentClass(RevRecForWIP, glPeriod?.wipAdjustmentId)}
                            onClick={() => onCreateRevRecForWip()}
                            disabled={processingUserAction || !!glPeriod?.wipAdjustmentId}>Create Journal Entry for WIP <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-billed-fixedfee-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "billedFixedFee"}
                            active={isProcessing && activeSectionId === "billedFixedFee"}
                            className={setAdjustmentClass(BilledFixedFee, glPeriod?.billedFixedFeeAdjustmentId)}
                            onClick={() => onBilledFixedFee()}
                            disabled={processingUserAction || !!glPeriod?.billedFixedFeeAdjustmentId}>Create Journal Entry for Billed Fixed-Fee Projects <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-billed-hybrid-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "billedHybrid"}
                            active={isProcessing && activeSectionId === "billedHybrid"}
                            className={setAdjustmentClass(BilledHybrid, glPeriod?.billedHybridAdjustmentId)}
                            onClick={() => onBilledHybrid()}
                            disabled={processingUserAction || !!glPeriod?.billedHybridAdjustmentId}>Create Journal Entry for Billed Hybrid Projects <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-billed-tnmandnte-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "billedTnM_NTE"}
                            active={isProcessing && activeSectionId === "billedTnM_NTE"}
                            className={setAdjustmentClass(BilledTnMandNTE, glPeriod?.billedTnMandNTEAdjustmentId)}
                            onClick={() => onBilledTnMandNte()}
                            disabled={processingUserAction || !!glPeriod?.billedTnMandNTEAdjustmentId}>Create Journal Entry for Billed T&M/NTE Projects <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-variance-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "fixedFeenHybridVariance"}
                            active={isProcessing && activeSectionId === "fixedFeenHybridVariance"}
                            className={setAdjustmentClass(FixedFeeHybridVarianceFlag, glPeriod?.fixedFeeVarianceAdjustmentId)}
                            onClick={() => onVariance()}
                            disabled={processingUserAction || !!glPeriod?.fixedFeeVarianceAdjustmentId}>Create Journal Entry for Closed Fixed Fee/Hybrid Project Variance <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                    <NavItem className="nav-flow-direction" />
                    <NavItem>
                        <NavLink tag="button" id="workflow-reports-tab" data-bs-toggle="pill" data-bs-target="#glPeriodDetails" role="tab"
                            aria-controls="glPeriodDetails" type="button"
                            aria-selected={activeSectionId === "reports"}
                            active={isProcessing && activeSectionId === "reports"}
                            className={setReportsClass()}
                            onClick={() => onReports()}
                            disabled={processingUserAction}>Closing Reports <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></NavLink>
                    </NavItem>
                </Nav>
            </div>
        </CardBody>;
    };

    return (<div id="glPeriodDetailsLayout" className="container pt-3">
        <Card>
            <CardHeader>
                {loading || statusText ? <p><em>{statusText}</em></p> : (glPeriod ? <GlPeriodHeader glPeriod={glPeriod} /> : (<p><em>Unable to load accounting period with ID {props.periodId}</em></p>))}
            </CardHeader>
            {loading || statusText ? <></> : renderGlPeriodDetailsLayout()}
        </Card>
        <ConfirmationDlg isOpen={isConfirmationDlgOpen} setIsOpen={setIsConfirmationDlgOpen} params={confirmationParams} />
        <MessageDlg isOpen={!!alertMessageParams} closeDlg={() => setAlertMessageParams(null)} params={alertMessageParams} />
    </div>
    );
};

export default GlPeridDetailsLayout;