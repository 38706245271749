import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../Utils';
import authService from './api-authorization/AuthorizeService'
import TablePager from './TablePager';

export interface GlPeriod {
    periodId: number;
    periodCode: string;
    startDate: string;
    endDate: string;
    isOpen: boolean;
    allowAdjustments: boolean;
};

const listPageSize = 12;

const GlPeriods = (props: any) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [glPeriods, setGlPeriods] = useState<GlPeriod[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateGlPeriods = async () => {
            const queryParams = new URLSearchParams({
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `glPeriods?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setGlPeriods(data.periods ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setGlPeriods([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setGlPeriods([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }
        populateGlPeriods();
    }, [startIndex]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const renderGlPeriodsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>Period</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th style={{ textAlign: 'center' }}>Is Open</th>
                                <th style={{ textAlign: 'center' }}>Allow Adjustment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {glPeriods.map(glPeriod =>
                                <tr key={glPeriod.periodCode}>
                                    <td><Link to={{ pathname: '/glPeriodDetails', search: '?periodId=' + glPeriod.periodId.toString() }}>{glPeriod.periodCode}</Link></td>
                                    <td>{formatDate(glPeriod.startDate)}</td>
                                    <td>{formatDate(glPeriod.endDate)}</td>
                                    <td className="text-black-50 text-center">{glPeriod.isOpen ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>}</td>
                                    <td className="text-black-50 text-center">{glPeriod.allowAdjustments ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for accounting periods" totalCount={totalCount}
                    itemCount={glPeriods.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="glPeriods" />
            </div>
        </>);
    }

    let contents = loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderGlPeriodsTable();

    return (
        <div>
            <h1 id="tableLabel">Accounting Periods</h1>
            <p>The following are all the accounting periods in Clearview.</p>
            {contents}
        </div>
    );
};

export default GlPeriods;