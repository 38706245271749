import { useState, useEffect } from 'react';
import { formatDateTime } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';

export type InFocusJobLogEntry = {
    jobLogID: number;
    jobID: number;
    startDate: string;
    endDate: string | null; 
    hadError: boolean;
    didRun: boolean;
    message: string | null;
    modifyDate: string;
    createDate: string;
    modifyBy: string;
    createBy: string;
};

const listPageSize = 25;

type AuditOverallPopulationHistoryProps = {
    version: number;
};

const AuditOverallPopulationHistory = (props: AuditOverallPopulationHistoryProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [version, setVersion] = useState(props.version);
    const [loading, setLoading] = useState(true);
    const [inFocusJobLogEntries, setInFocusJobLogEntries] = useState<InFocusJobLogEntry[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateInFocusJobLogEntries = async () => {
            if (props.version !== version && startIndex !== 0) {
                setStartIndex(0);
                setVersion(props.version);
            }
            const queryParams = new URLSearchParams({
                jobName: "z_AuditOverallPopulation",
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `inFocusJobLog?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setInFocusJobLogEntries(data.entries ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setInFocusJobLogEntries([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setInFocusJobLogEntries([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }
        populateInFocusJobLogEntries();
    }, [startIndex, props.version, version]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const renderGlPeriodsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th style={{ textAlign: 'center' }}>Error</th>
                                <th style={{ textAlign: 'center' }}>Did Run</th>
                                <th>Created by</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inFocusJobLogEntries.length > 0 ? inFocusJobLogEntries.map(entry =>
                                <tr key={entry.jobLogID}>
                                    <td>{entry.jobLogID}</td>
                                    <td>{formatDateTime(entry.startDate)}</td>
                                    <td>{entry.endDate ? formatDateTime(entry.endDate) : ""}</td>
                                    <td className="text-black-50 text-center">{entry.hadError ? <i className="bi bi-check-square text-danger"></i> : <i className="bi bi-square"></i>}</td>
                                    <td className="text-black-50 text-center">{entry.didRun ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>}</td>
                                    <td>{entry.createBy ?? ''}</td>
                                    <td>{entry.message ?? ''}</td>
                                </tr>
                            ) : <tr><td colSpan={7} style={{ textAlign: 'center' }}>The job log for "Audit Overall Population" is empty</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for accounting periods" totalCount={totalCount}
                    itemCount={inFocusJobLogEntries.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="glPeriods" />
            </div>
        </>);
    }

    let contents = loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderGlPeriodsTable();

    return (
        <div>
            {contents}
        </div>
    );
};

export default AuditOverallPopulationHistory;