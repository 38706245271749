import React, { useState, useEffect } from 'react';
import { formatCurrency, formatDateTime } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';
import { AdjustmentItem } from './PreviousPeriodWipItems';

type AdjustmentItemsProps = {
    adjustmentId: number | null;
};

const listPageSize = 25;

const AdjustmentItems = (props: AdjustmentItemsProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [adjustmentItems, setAdjustmentItems] = useState<AdjustmentItem[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateAdjustmentItems = async () => {
            if (!props.adjustmentId) {
                setTotalCount(0);
                setAdjustmentItems([]);
                setStatusText("");
                setLoading(false);
                return;
            }

            const queryParams = new URLSearchParams({
                adjustmentId: props.adjustmentId?.toString() ?? '',
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `adjustmentItems?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setAdjustmentItems(data.items ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setAdjustmentItems([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setAdjustmentItems([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }

        populateAdjustmentItems();
    }, [startIndex, props.adjustmentId]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const groupedItems = adjustmentItems.reduce((groups, item) => {
        const groupKey = (item.projectPath ?? '')
            + item.glName
            + item.glAcctPath
            + (item.glOrgName ?? '');
        if (!groups.has(groupKey)) {
            groups.set(groupKey, []);
        }
        groups.get(groupKey)?.push(item);
        return groups;
    }, new Map<string, AdjustmentItem[]>());

    const renderAdjustment = (items: AdjustmentItem[]) => {
        const groupLeader = items[0];
        const groupKey = (groupLeader.projectPath ?? '')
            + groupLeader.glName
            + groupLeader.glAcctPath
            + (groupLeader.glOrgName ?? '');
        return <React.Fragment key={groupKey}>
            <tr key={groupKey}>
                <td colSpan={6}>Project "{groupLeader.projectPath ?? '-'}", Account Name "{groupLeader.glName}"<br />Account Path "{groupLeader.glAcctPath}", Org. Unit "{groupLeader.glOrgName}"</td>
            </tr>
            {items.map(item =>
                <tr key={'item' + item.gjLineId.toString()}>
                    <td>{item.gjLineId.toString()}</td>
                    <td>{item.glPeriodCode}</td>
                    <td>{formatDateTime(item.createDate)}</td>
                    <td>{formatCurrency(item.amount)}</td>
                    <td><small>{item.modifyBy}</small></td>
                    <td><small>{item.glComments ?? ''}</small></td>
                </tr>
            )}
        </React.Fragment>
    };

    const renderAdjustmentItemsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>Item ID</th>
                                <th>Period</th>
                                <th>Create Date</th>
                                <th>Amount</th>
                                <th>Modified by</th>
                                <th>Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupedItems.size > 0 ? Array.from(groupedItems).map(([, itemsInGroup]) => renderAdjustment(itemsInGroup))
                                : <tr><td colSpan={6} style={{ textAlign: 'center' }}>No adjustment items were found.</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for adjustment items" totalCount={totalCount}
                    itemCount={adjustmentItems.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="adjustmentItems" />
            </div>
        </>);
    }

    return loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderAdjustmentItemsTable()
};

export default AdjustmentItems;