import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export type ConfirmationParams = {
    message: string;
    setResult: (result: boolean) => Promise<void>;
}

type ConfirmationDlgProps = {
    isOpen: boolean;
    setIsOpen: (result: boolean) => void;
    params: ConfirmationParams | null;
};

const ConfirmationDlg = (props: ConfirmationDlgProps) => {
    const closeDlg = () => props.setIsOpen(false);
    const onCancel = () => {
        props.params?.setResult(false);
        props.setIsOpen(false);
    };

    const onOkay = async () => {
        props.setIsOpen(false);
        if (props.params) {
            await props.params.setResult(true);
        }
    };

    return <Modal isOpen={props.isOpen}
        toggle={closeDlg}>
        <ModalHeader>Please Confirm</ModalHeader>
        <ModalBody>
            <div className="row">
                <div className="col-md-12" dangerouslySetInnerHTML={{ __html: props.params?.message ?? "" }}></div>
            </div>
            <div className="row pt-3">
                <div className="col-md-12 d-flex justify-content-end">
                    <button className="btn btn-primary" type="submit" onClick={onOkay}>OK</button>
                    <button className="btn btn-secondary mx-1" type="reset" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </ModalBody>
    </Modal>;
};

export default ConfirmationDlg;