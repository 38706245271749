import React, { useState, useEffect } from 'react';
import { formatDate } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';

type TimesheetItemsWithMismatchingOrgUnitsProps = {
    period: GlPeriodViewModel;
    version: number;
};

type TimesheetItem = {
    employeeName: string | null;
    employeeOrgUnitName: string | null;
    fromOrgUnitNames: string | null;
    periodCode: string | null;
    timesheetID: number;
    revisions: number;
    modifyDate: string;
    id: number;
    projectCode: string;
    projectName: string;
    projectPath: string;
    workDate: string;
    workHrs: number;
    billHrs: number;
};

const listPageSize = 25;

const TimesheetItemsWithMismatchingOrgUnits = (props: TimesheetItemsWithMismatchingOrgUnitsProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [timesheetItems, setTimesheetItems] = useState<TimesheetItem[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateTimesheetItems = async () => {
            const queryParams = new URLSearchParams({
                endDate: props.period.endDate,
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `timesheetItems?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setTimesheetItems(data.items ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setTimesheetItems([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setTimesheetItems([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }

        populateTimesheetItems();
    }, [startIndex, props.period.endDate, props.version]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const itemsByOrgs = timesheetItems.reduce((groups, item) => {
        const groupKey = (item.employeeOrgUnitName ?? '');
        if (!groups.has(groupKey)) {
            groups.set(groupKey, []);
        }
        groups.get(groupKey)?.push(item);
        return groups;
    }, new Map<string, TimesheetItem[]>());

    const renderItemsByEmployees = (items: TimesheetItem[]) => {
        const groupLeader = items[0];
        const groupKey = (groupLeader.employeeName ?? '')
            + (groupLeader.fromOrgUnitNames ?? '');
        return <React.Fragment key={groupKey}>
            <tr key={groupKey}>
                <td colSpan={7}>Employee: {groupLeader.employeeName ?? '-'}<br />{groupLeader.fromOrgUnitNames}</td>
            </tr>
            {items.map(item =>
                <tr key={'item' + item.id.toString()}>
                    <td>{item.timesheetID.toString()}</td>
                    <td title={'Revisions ' + item.revisions.toString() }>{formatDate(item.modifyDate)}</td>
                    <td>{item.id.toString()}</td>
                    <td>{item.periodCode ?? ''}</td>
                    <td>{formatDate(item.workDate)}</td>
                    <td title={item.projectName + (item.projectName?.toLowerCase() !== item.projectPath?.toLowerCase() ?
                        " ( Path: " + item.projectPath + ")" : "")}>{item.projectCode}</td>
                    <td title={"Work hours: " + item.workHrs.toFixed(1)}>{item.billHrs.toFixed(1)}</td>
                </tr>
            )}
        </React.Fragment>
    };

    const renderByOrgs = (items: TimesheetItem[]) => {
        const groupLeader = items[0];
        const groupKey = (groupLeader.employeeOrgUnitName ?? '');

        const itemsByEmployee = items.reduce((groups, item) => {
            const groupKey = (item.employeeName ?? '')
                + (item.fromOrgUnitNames ?? '');
            if (!groups.has(groupKey)) {
                groups.set(groupKey, []);
            }
            groups.get(groupKey)?.push(item);
            return groups;
        }, new Map<string, TimesheetItem[]>());

        return <React.Fragment key={groupKey}>
            <tr key={groupKey}>
                <td colSpan={7}>Org. Unit "{groupLeader.employeeOrgUnitName ?? '-'}"</td>
            </tr>
            {Array.from(itemsByEmployee).map(([, itemsInGroup]) => renderItemsByEmployees(itemsInGroup))}
        </React.Fragment>
    };

    const renderTimesheetItemsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>Timesheet ID</th>
                                <th>Last Mod.</th>
                                <th>Item ID</th>
                                <th>Period</th>
                                <th>Work Date</th>
                                <th>Project</th>
                                <th>Bill Hrs.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {itemsByOrgs.size > 0 ? Array.from(itemsByOrgs).map(([, itemsInGroup]) => renderByOrgs(itemsInGroup))
                                : <tr><td colSpan={7} style={{ textAlign: 'center' }}>No timesheet items with mismatching org. units were found.</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for timesheet items" totalCount={totalCount}
                    itemCount={timesheetItems.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="timesheetItems" />
            </div>
        </>);
    }

    return loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderTimesheetItemsTable()
};

export default TimesheetItemsWithMismatchingOrgUnits;