import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'

const AuthorizeRoute = (props: any) => {
    const [ready, setReady] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState(-1);

    useEffect(() => {
        const populateAuthenticationState = async () => {
            const authenticated = await authService.isAuthenticated();
            setIsAuthenticated(authenticated);
            if (authenticated && subscriptionId <= 0) {
                const newSubscriptionId = authService.subscribe(() => {
                    setReady(false);
                    setIsAuthenticated(false);
                    populateAuthenticationState();
                });
                setSubscriptionId(newSubscriptionId);
            }
            setReady(true);
        }

        populateAuthenticationState();

        return () => {
            if (subscriptionId > 0) {
                authService.unsubscribe(subscriptionId);
            }
        };
    }, [subscriptionId]);

    var link = document.createElement("a");
    link.href = props.path;
    const returnUrl = `${link.protocol}//${link.host}${window.location.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
    if (!ready) {
        return <div></div>;
    } else {
        const { element } = props;
        return isAuthenticated ? element : <Navigate replace to={redirectUrl} />;
    }
}

export default AuthorizeRoute;