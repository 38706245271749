import React, { useState, useEffect } from 'react';
import { BillReview } from './IncompleteBillReviews';
import { formatDateTime } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';

type BillReviewPC = {
    reviewPCID: number;
    projectID: number;
    fixedFeePC: number;
    currentICC: number;
    lumpSum: number;
    modifyBy: string;
    billReviewID: number;
    billReview: BillReview;
};

type IncompleteBillReviewPCsProps = {
    cutoffDate: Date;
    version: number;
};

const listPageSize = 25;

const IncompleteBillReviewPCs = (props: IncompleteBillReviewPCsProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [version, setVersion] = useState(props.version);
    const [loading, setLoading] = useState(true);
    const [billReviewPCs, setBillReviewPCs] = useState<BillReviewPC[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateBillReviewPCs = async () => {
            if (props.version !== version && startIndex !== 0) {
                setStartIndex(0);
                setVersion(props.version);
            }
            const queryParams = new URLSearchParams({
                cutoffTime: props.cutoffDate.toISOString(),
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `billReviewPCs?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setBillReviewPCs(data.pcs ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setBillReviewPCs([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setBillReviewPCs([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }
        populateBillReviewPCs();
    }, [startIndex, props.cutoffDate, props.version, version]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const groupedItems = billReviewPCs.reduce((groups, pc) => {
        if (!groups.has(pc.billReviewID)) {
            groups.set(pc.billReviewID, []);
        }
        groups.get(pc.billReviewID)?.push(pc);
        return groups;
    }, new Map<number, BillReviewPC[]>());

    const renderBillReview = (items: BillReviewPC[]) => {
        const billReview = items[0].billReview;
        return <React.Fragment key={'billreview' + billReview.billReviewID.toString()}>
            <tr key={'review' + billReview.billReviewID.toString()}>
                <td colSpan={5}>Bill Review (ID {billReview.billReviewID}, created at {formatDateTime(billReview.createDate)}, Project ID {billReview.projectID.toString()})<br />PM Reviewed: {billReview.isPMReviewed ? 'Y' : 'N'}, PA Reviewed: {billReview.isPAReviewed ? 'Y' : 'N'}, Last modifed by {billReview.modifyBy}</td>
            </tr>
            {items.map(item =>
                <tr key={'pc' + item.reviewPCID.toString()}>
                    <td>{item.reviewPCID.toString()}</td>
                    <td>{item.fixedFeePC.toFixed(2)}</td>
                    <td>{item.currentICC.toFixed(2)}</td>
                    <td>{item.lumpSum.toFixed(2)}</td>
                    <td>{item.modifyBy}</td>
                </tr>
            )}
        </React.Fragment>

    };

    const renderBillReviewPCsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>PC ID</th>
                                <th>Fixed Fee PC</th>
                                <th>Current ICC</th>
                                <th>Lump Sum</th>
                                <th>Modified by</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupedItems.size > 0 ? Array.from(groupedItems).map(([, itemsInGroup]) => renderBillReview(itemsInGroup))
                                : <tr><td colSpan={5} style={{ textAlign: 'center' }}>No incomplete bill review PCs were found.</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for incomplete bill review PCs" totalCount={totalCount}
                    itemCount={billReviewPCs.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="billReviewPCs" />
            </div>
        </>);
    }

    let contents = loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderBillReviewPCsTable();

    return (
        <div>
            {contents}
        </div>
    );
};

export default IncompleteBillReviewPCs;