import { useState, useEffect } from 'react';
import { BillReview } from './IncompleteBillReviews';
import { formatDateTime } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';

type IncompleteBillReviewsListProps = {
    cutoffDate: Date;
    version: number;
};

const listPageSize = 25;

const IncompleteBillReviewsList = (props: IncompleteBillReviewsListProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [version, setVersion] = useState(props.version);
    const [loading, setLoading] = useState(true);
    const [billReviews, setBillReviews] = useState<BillReview[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    const { cutoffDate: propsCutoffDate, version: propsVersion } = props;

    useEffect(() => {
        const populateBillReviews = async () => {
            if (propsVersion !== version && startIndex !== 0) {
                setStartIndex(0);
                setVersion(propsVersion);
            }
            const queryParams = new URLSearchParams({
                cutoffTime: propsCutoffDate.toISOString(),
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `billReviews?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setBillReviews(data.reviews ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setBillReviews([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setBillReviews([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }
        populateBillReviews();
    }, [startIndex, propsCutoffDate, propsVersion, version]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const renderBillReviewsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Time Created</th>
                                <th>Project</th>
                                <th style={{ textAlign: 'center' }}>PM Reviewed</th>
                                <th style={{ textAlign: 'center' }}>PA Reviewed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {billReviews.length > 0 ? billReviews.map((review) => 
                                <tr key={'item' + review.billReviewID.toString()}>
                                    <td>{review.billReviewID.toString()}</td>
                                    <td>{formatDateTime(review.createDate)}</td>
                                    <td>{review.projectName}</td>
                                    <td className="text-black-50 text-center">{review.isPMReviewed ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>}</td>
                                    <td className="text-black-50 text-center">{review.isPAReviewed ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>}</td>
                                </tr>)
                                : <tr><td colSpan={5} style={{ textAlign: 'center' }}>No incomplete bill reviews were found.</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for incomplete bill review items" totalCount={totalCount}
                    itemCount={billReviews.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="billReviews" />
            </div>
        </>);
    }

    let contents = loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderBillReviewsTable();

    return (
        <div>
            {contents}
        </div>
    );
};

export default IncompleteBillReviewsList;