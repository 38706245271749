import { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { GlPeriod } from '../GlPeriods';
import AdjustmentItems from './AdjustmentItems';
import PreviousPeriod from './PreviousPeriod';

type PreviousPeriodWipsProps = {
    previousPeriod: GlPeriod | null;
    previousPeriodWipGjId: number | null;
    previousPeriodWipReversalGjId: number | null;
};

const PreviousPeriodWips = (props: PreviousPeriodWipsProps) => {
    const [openSections, setOpenSections] = useState(["PreviousPeriod", "PreviousJournalEntry", "ReversedPreviousJournalEntry"]);
    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    return (<div className="container">
        {
            //@ts-ignore
            <Accordion open={openSections} toggle={setOpenSection}>
                <AccordionItem>
                    <AccordionHeader targetId="PreviousPeriod">Previous Accounting Period</AccordionHeader>
                    <AccordionBody accordionId="PreviousPeriod">
                        <PreviousPeriod previousPeriod={props.previousPeriod} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="PreviousJournalEntry">Adjustment for Previous Accounting Period WIP</AccordionHeader>
                    <AccordionBody accordionId="PreviousJournalEntry">
                        <AdjustmentItems adjustmentId={props.previousPeriodWipGjId} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="ReversedPreviousJournalEntry">Adjustment Reversed Previous Accounting Period WIP</AccordionHeader>
                    <AccordionBody accordionId="ReversedPreviousJournalEntry">
                        <AdjustmentItems adjustmentId={props.previousPeriodWipReversalGjId} />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        }
    </div>);
};

export default PreviousPeriodWips;