import React, { useState, useEffect } from 'react';
import { formatCurrency } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';
import GlAmountDetailsByOrgUnits from './GlAmountDetailsByOrgUnits';
import { GlAmount } from './GlAmounts';
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';

export type GlAmountDetail = GlAmount & {
    itemType: string;
}

type GlAmountDetailsProps = {
    period: GlPeriodViewModel;
    amounts: GlAmount[];
    accType: string;
    loading: boolean;
};

const listPageSize = 25;

const GlAmountDetails = (props: GlAmountDetailsProps) => {
    const [startIndex, setStartIndex] = useState(0);
    const [statusText, setStatusText] = useState("");
    const [loading, setLoading] = useState(true);
    const [selectedBaseCode, setSelectedBaseCode] = useState<string | null>(null);
    const [selectedGlAccount, setSelectedGlAccount] = useState<string | null>(null);
    const [selectedOrgPath, setSelectedOrgPath] = useState<string | null>(null);
    const [amountDetails, setAmountDetails] = useState<GlAmountDetail[]>([]);

    useEffect(() => {
        const getAmountLineItems = async () => {
            if (!selectedBaseCode
                && !selectedGlAccount
                && !selectedOrgPath) {
                setAmountDetails([]);
                return;
            }
            setLoading(true);
            setStatusText("Loading...");

            const queryParams = new URLSearchParams({
                baseCode: selectedBaseCode ?? "",
                glAccName: selectedGlAccount ?? "",
                orgPath: selectedOrgPath ?? "",
                accType: props.accType
            });
            const url = `glPeriods/${props.period.periodId}/getAmountDetailsByOrgUnits?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setAmountDetails(data ?? []);
                    setStatusText("");
                } catch (error) {
                    setAmountDetails([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setAmountDetails([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }

        getAmountLineItems();
    }, [props.period.periodId, props.accType, selectedBaseCode, selectedGlAccount, selectedOrgPath]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const pagedItems = props.amounts.slice(startIndex, startIndex + listPageSize);

    const onGoToLineItem = (e: React.MouseEvent<HTMLButtonElement>, amount: GlAmount): boolean => {
        e.preventDefault();
        if (amount.baseCode === selectedBaseCode
            && amount.glAccountName === selectedGlAccount
            && amount.orgPath === selectedOrgPath) {
            setSelectedBaseCode(null);
            setSelectedGlAccount(null);
            setSelectedOrgPath(null);
        } else {
            setSelectedBaseCode(amount.baseCode);
            setSelectedGlAccount(amount.glAccountName);
            setSelectedOrgPath(amount.orgPath);
        }
        return false;
    };

    const showSpinner = (amount: GlAmount): boolean => {
        return loading && amount.baseCode === selectedBaseCode
            && amount.glAccountName === selectedGlAccount
            && amount.orgPath === selectedOrgPath;
    };

    const renderAmount = (amount: GlAmount) => {
        return <React.Fragment key={'amountDetails' + amount.baseCode + amount.glAccountName + (amount.orgPath ?? "")}>
            <tr>
                <td>&nbsp;</td>
                <td>{amount.orgName}</td>
                <td>{amount.orgPath}</td>
                <td>{formatCurrency(amount.previousPeriodAmount ?? 0)}</td>
                <td><button className="btn btn-link btn-sm p-0" onClick={(e) => onGoToLineItem(e, amount)}>{formatCurrency(amount.amount)} {!showSpinner(amount) ? <></> : <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>}</button></td>
            </tr>
            {selectedBaseCode !== amount.baseCode
                || selectedGlAccount !== amount.glAccountName
                || selectedOrgPath !== amount.orgPath ? <></>
                : <tr><td colSpan={2}>&nbsp;</td><td colSpan={3}><GlAmountDetailsByOrgUnits period={props.period} amounts={amountDetails} accType={props.accType} orgPath={selectedOrgPath ?? ""} loading={loading} /></td></tr>}
        </React.Fragment>
    };

    return (<>
        {statusText ? <div className="row"><div className="col-12"><div className={statusText !== "Loading..." ? "alert alert-danger" : "alert"} role="alert">
            <div>{statusText}</div>
        </div></div></div> : <></>}
        <div className="row">
            <div className="col-12 table-responsive-md fst-italic small">
                <table className="table table-striped table-hover" aria-labelledby="tableLabel">
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Org. Unit</th>
                            <th>Org. Path</th>
                            <th>{props.period.previousPeriod?.periodCode ?? "-"}</th>
                            <th>{props.period.periodCode}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pagedItems.length > 0 ? pagedItems.map((amount) => renderAmount(amount))
                            : <tr><td>&nbsp;</td><td colSpan={4} style={{ textAlign: 'center' }}>{props.loading? "Loading data..." : "The sub-amount list is empty."}</td></tr>}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row">
            <TablePager ariaLabel="Page navigation for amount details" totalCount={props.amounts.length}
                itemCount={pagedItems.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="amountDetails" />
        </div>
    </>);
};

export default GlAmountDetails;