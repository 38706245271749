import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export type MessageDlgParams = {
    title: string;
    message: string;
}

type MessageDlgProps = {
    isOpen: boolean;
    closeDlg: () => void;
    params: MessageDlgParams | null;
};

const MessageDlg = (props: MessageDlgProps) => {
    return <Modal isOpen={props.isOpen}
        toggle={props.closeDlg}>
        <ModalHeader>{props.params?.title ?? ""}</ModalHeader>
        <ModalBody>
            <div className="row">
                <div className="col-md-12" dangerouslySetInnerHTML={{ __html: props.params?.message ?? "" }}></div>
            </div>
            <div className="row pt-3">
                <div className="col-md-12 d-flex justify-content-end">
                    <button className="btn btn-primary" type="submit" onClick={props.closeDlg}>OK</button>
                </div>
            </div>
        </ModalBody>
    </Modal>;
};

export default MessageDlg;