import { useState, useEffect } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService'
import { ServiceSettingsViewModel, ServiceSettingsViewModelForm } from '../ViewModels/ServiceSettingsForm';
import { PostError } from '../../Utils';
import BaseCodes from './BaseCodes';
import Firms from './Firms';

const ServiceSettings = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [openSections, setOpenSections] = useState(["SettingsForm", "BaseCodes", "FirmIDs"]);
    const [statusText, setStatusText] = useState("");
    const [serviceSettings, setServiceSettings] = useState(new ServiceSettingsViewModel());

    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    useEffect(() => {
        const populateSericeSettings = async () => {
            const token = await authService.getAccessToken();
            const response = await fetch("serviceSettings", {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setServiceSettings(data);
                    setStatusText("");
                } catch (error) {
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setStatusText(await response.text());
            }
            setLoading(false);
        }
        populateSericeSettings();
    }, []);

    const onSubmit = async (data: ServiceSettingsViewModel) => {
        const token = await authService.getAccessToken();
        const headers: Record<string, string> = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        try {
            const response = await fetch("serviceSettings", {
                method: 'POST',
                headers,
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                setStatusText(`Unable to save service settings. '${response.statusText}'`);
            } else {
                const responseData = await response.json();
                if (responseData.hasErrors) {
                    if (responseData.message) {
                        setStatusText(responseData.message);
                    } else {
                        setStatusText("Failed to save service settings.");
                    }
                } else {
                    if (responseData.message) {
                        setStatusText(responseData.message);
                    } else {
                        setStatusText("");
                    }
                }
            }
        } catch (error) {
            if (error instanceof PostError) {
                setStatusText(`Unable to save the service settings. '${error}'`);
            } else {
                setStatusText(`Unable to save the service settings.`);
            }
        }
    };

    return loading
        ? <p><em>{statusText}</em></p>
        : (<div className="container">
            {statusText ? <div className="alert alert-danger" role="alert">
                <div>{statusText}</div>
            </div> : <></>
            }
            {
                //@ts-ignore
                <Accordion open={openSections} toggle={setOpenSection}>
                    <AccordionItem>
                        <AccordionHeader targetId="SettingsForm">Background Service Settings{serviceSettings?.isSandbox ? " (Sandbox " + serviceSettings.dataSource + ":" + serviceSettings.catalog  + ")" : ""}</AccordionHeader>
                        <AccordionBody accordionId="SettingsForm">
                            <ServiceSettingsViewModelForm serviceSettingsViewModel={serviceSettings} onSubmit={onSubmit} />
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="BaseCodes">Base Codes</AccordionHeader>
                        <AccordionBody accordionId="BaseCodes">
                            <BaseCodes selectedBaseCodeIds={serviceSettings.basedCodeIDsForSjRemoval} />
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="FirmIDs">Firms</AccordionHeader>
                        <AccordionBody accordionId="FirmIDs">
                            <Firms selectedFirmIds={serviceSettings.invoicePeriodFixExcludedFirmIds} />
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            }
        </div>);
};

export default ServiceSettings;