import { ApplicationPaths } from './ApiAuthorizationConstants';

export type User = {
    name: string;
};
export class AuthorizeService {
    _callbacks: any[] = [];
    _nextSubscriptionId: number = 0;
    _user: User | null = null;
    _accessTokenName: string | null = null;
    _accessToken: string | null = null;
    _isAuthenticated = false;

    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    _popUpDisabled = true;

    async isAuthenticated() {
        const user = await this.getUser();
        return !!user;
    }

    async getUser() {
        if (this._user) {
            return this._user;
        }

        await this.ensureAccecssTokenInitialized();

        return this._user;
    }

    async getAccessToken() {
        await this.ensureAccecssTokenInitialized();
        return this._accessToken;
    }

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    signIn(state: any) {
        try {
            const loginUrl = `${ApplicationPaths.Login}${state.returnUrl ? `?{returnUrl=${state.returnUrl}` : ''}`;
            if (window.location.pathname !== loginUrl) {
                console.log("Reloading " + window.location.href + "...");
                window.location.href = loginUrl;
            }
            return this.redirect();
        } catch (loginError) {
            console.log("Login error: ", loginError);
            return this.error('There was an error signing in.');
        }
    }

    async completeSignIn() {
        try {
            await this.ensureAccecssTokenInitialized();
            return this.success();
        } catch (error) {
            console.log('There was an error signing in: ', error);
            return this.error('There was an error signing in.');
        }
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOut(state: any) {
        await this.ensureAccecssTokenInitialized();

        if (!this._accessTokenName || !this._accessToken) {
            return;
        }

        try {
            const data = new URLSearchParams();
            data.append(this._accessTokenName, this._accessToken);

            const response = await fetch(ApplicationPaths.LogOut, {
                method: 'POST',
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: data
            });

            if (response.ok) {
                this.updateState(undefined);
                return this.success(state);
            }

            return this.error(`There was an error trying to log out. '${response.statusText}'.`);

        } catch (signOutError) {
            console.log("Signout error: ", signOutError);
            return this.error(signOutError);
        }
    }

    updateState(tokenSet: any) {
        if (tokenSet) {
            this._accessTokenName = tokenSet.FormFieldName;
            this._accessToken = tokenSet.RequestToken;
            this._isAuthenticated = true;
            this._user = {
                name: tokenSet.UserName
            };
        } else {
            this._accessTokenName = null;
            this._accessToken = null;
            this._isAuthenticated = false;
            this._user = null;
        }

        this.notifySubscribers();
    }

    subscribe(callback: any) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        const index = subscriptionIndex[0].index;
        if (index !== undefined)
            this._callbacks.splice(index, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    createArguments(state: any) {
        return { useReplaceToNavigate: true, data: state };
    }

    error(message: any) {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    success(state?: any) {
        return { status: AuthenticationResultStatus.Success, state };
    }

    redirect() {
        return { status: AuthenticationResultStatus.Redirect };
    }

    async ensureAccecssTokenInitialized() {
        if (this._accessToken) {
            return;
        }

        const response = await fetch(ApplicationPaths.GetAccessToken);
        if (!response.ok) {
            return;
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") === -1) {
            const results = await response.text();
            console.log(results);
            return;
        }

        const tokenSet = await response.json();
        this.updateState(tokenSet);
    }

    static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};
