import { useState, useEffect } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';
import GlAmounts, { GlAmount } from './GlAmounts';
import ReportLinks from './ReportLinks';
import authService from '../api-authorization/AuthorizeService'

type ReportsProps = {
    period: GlPeriodViewModel;
    version: number;
};

const Reports = (props: ReportsProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [loading, setLoading] = useState(true);
    const [openSections, setOpenSections] = useState(["Links", "Income", "Expense"]);
    const [glAmounts, setGlAmounts] = useState<GlAmount[]>([]);
    const [version, setVersion] = useState(props.version);
    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    useEffect(() => {
        const getAmounts = async () => {
            if (props.version !== version) {
                setVersion(props.version);
            }
            const url = `glPeriods/${props.period.periodId}/getAmounts`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setGlAmounts(data ?? []);
                    setStatusText("");
                } catch (error) {
                    setGlAmounts([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setGlAmounts([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }

        getAmounts();
    }, [props.period.periodId, props.version, version]);

    return loading || statusText
        ? (<p><em>{statusText}</em></p>) : (<div className="container">
        {
            //@ts-ignore
            <Accordion open={openSections} toggle={setOpenSection}>
                <AccordionItem>
                    <AccordionHeader targetId="Links">Report Links</AccordionHeader>
                    <AccordionBody accordionId="Links">
                        <ReportLinks period={props.period} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="Income">Income (Accrual)</AccordionHeader>
                    <AccordionBody accordionId="Income">
                        <GlAmounts period={props.period} amounts={glAmounts.filter(a => a.accountType === "Income")} accType="Income" />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="Expense">Expense (Accrual)</AccordionHeader>
                    <AccordionBody accordionId="Expense">
                        <GlAmounts period={props.period} amounts={glAmounts.filter(a => a.accountType === "Expense")} accType="Expense" />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        }
    </div>);
};

export default Reports;