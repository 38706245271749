import { useState, useEffect } from 'react'
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { LogoutActions, ApplicationPaths } from './ApiAuthorizationConstants';
import { getReturnUrl } from './Login';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
type LogoutProps = {
    action: string;
};

const Logout = (props: LogoutProps) => {
    const [message, setMessage] = useState("");
    const [isReady, setIsReady] = useState(false);
    const [authenticated, setAuthenticated] = useState(true);
    const action = props.action;

    useEffect(() => {
        const navigateToReturnUrl = (returnUrl: string) => {
            return window.location.replace(returnUrl);
        };

        const populateAuthenticationState = async () => {
            const authenticated = await authService.isAuthenticated();
            setIsReady(true);
            setAuthenticated(authenticated);
        };

        const doLogout = async (returnUrl: string) => {
            const state = { returnUrl };
            if (authenticated) {
                const result: any = await authService.signOut(state);
                switch (result.status) {
                    case AuthenticationResultStatus.Redirect:
                        break;
                    case AuthenticationResultStatus.Success:
                        await navigateToReturnUrl(returnUrl);
                        break;
                    case AuthenticationResultStatus.Fail:
                        setMessage(result.message);
                        break;
                    default:
                        throw new Error("Invalid authentication result status.");
                }
            } else {
                setMessage("You successfully logged out!");
            }
        }

        switch (action) {
            case LogoutActions.Logout:
                if (!!window.history.state.usr.local) {
                    doLogout(getReturnUrl() ?? `${window.location.origin}/`);
                } else {
                    // This prevents regular links to <app>/authentication/logout from triggering a logout
                    setMessage("The logout was not initiated from within the page.");
                    setIsReady(true);
                }
                break;
            case LogoutActions.LoggedOut:
                window.location.href = `${ApplicationPaths.Login}?returnUrl=${encodeURIComponent(getReturnUrl() ?? "")}`;
                setIsReady(true);
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }

        populateAuthenticationState();

        // Optional cleanup code when the component is unmounted or re-rendered
        return () => {
            console.log('Component unmounted');
        };
    }, [action, authenticated]);

    if (!isReady) {
        return <div></div>
    }
    if (!!message) {
        return (<div>{message}</div>);
    }

    switch (action) {
        case LogoutActions.Logout:
            return (<div>Processing logout</div>);
        case LogoutActions.LogoutCallback:
            return (<div>Processing logout callback</div>);
        case LogoutActions.LoggedOut:
            return (<div>{message}</div>);
        default:
            throw new Error(`Invalid action '${action}'`);
    }
}

export default Logout;