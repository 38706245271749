import { useState } from 'react';

type SaleItemBaseCodesFilterProps = {
    basedCodeIDsForSjRemoval: string;
    submittingData: boolean
    setBasedCodeIDsForSjRemoval: (baseCodeIDs: string) => void;
};

const validateBasedCodeIDsForSjRemoval = (basedCodeIDs: string): boolean => {
    const basedCodeIDArray: number[] = basedCodeIDs.split(",").map(Number);
    return !basedCodeIDArray.some(num => Number.isNaN(num)
        || num < 0
        || !Number.isInteger(num));
}

const SaleItemBaseCodesFilter = (props: SaleItemBaseCodesFilterProps) => {
    const [basedCodeIDsForSjRemoval, setBasedCodeIDsForSjRemoval] = useState(props.basedCodeIDsForSjRemoval);
    const [inputClass, setInputClass] = useState("form-control");
    const onBasedCodeIDsForSjRemovalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBasedCodeIDsForSjRemoval(e.target.value);
    };
    const onUpdateBasedCodeIDsForSjRemoval = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (validateBasedCodeIDsForSjRemoval(basedCodeIDsForSjRemoval)) {
            setInputClass("form-control is-valid");
            props.setBasedCodeIDsForSjRemoval(basedCodeIDsForSjRemoval);
        } else {
            setInputClass("form-control is-invalid");
        }
    };

    return (<>
        <div className="row mb-3">
            <div className="form-group col-md-12">
                <label htmlFor="basedCodeIDsForSjRemoval">Base Code IDs:</label>
                <input type="text" className={inputClass} id="basedCodeIDsForSjRemoval"
                    disabled={props.submittingData}
                    value={basedCodeIDsForSjRemoval}
                    onChange={(e) => onBasedCodeIDsForSjRemovalChange(e)} />
            </div>
        </div>
        <div className="row mb-3">
            <div className="form-group col-md-12 d-flex justify-content-end">
                <button type="submit" className="btn btn-secondary" id="updateBasedCodeIDsForSjRemoval"
                    disabled={props.submittingData}
                    onClick={onUpdateBasedCodeIDsForSjRemoval}>Update</button>
            </div>
        </div>
    </>);
};

export default SaleItemBaseCodesFilter;