import React, { useState, useEffect } from 'react';
import { BillReview } from './IncompleteBillReviews';
import { formatDateTime } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';

type BillReviewItem = {
    itemBRID: number;
    timeLineID: string;
    eRLineID: string;
    pJLineID: string;
    description: string;
    isPMReviewed: boolean;
    isPAReviewed: boolean;
    billReviewID: number;
    billReview: BillReview;
};

type IncompleteBillReviewItemsProps = {
    cutoffDate: Date;
    version: number;
};

const listPageSize = 25;

const IncompleteBillReviewItems = (props: IncompleteBillReviewItemsProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [version, setVersion] = useState(props.version);
    const [loading, setLoading] = useState(true);
    const [billReviewItems, setBillReviewItems] = useState<BillReviewItem[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateBillReviewItems = async () => {
            if (props.version !== version && startIndex !== 0) {
                setStartIndex(0);
                setVersion(props.version);
            }
            const queryParams = new URLSearchParams({
                cutoffTime: props.cutoffDate.toISOString(),
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `billReviewItems?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setBillReviewItems(data.items ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setBillReviewItems([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setBillReviewItems([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }
        populateBillReviewItems();
    }, [startIndex, props.cutoffDate, props.version, version]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const groupedItems = billReviewItems.reduce((groups, item) => {
        if (!groups.has(item.billReviewID)) {
            groups.set(item.billReviewID, []);
        }
        groups.get(item.billReviewID)?.push(item);
        return groups;
    }, new Map<number, BillReviewItem[]>());

    const renderBillReview = (items: BillReviewItem[]) => {
        const billReview = items[0].billReview;
        return <React.Fragment key={'billreview' + billReview.billReviewID.toString()}>
            <tr key={'billreview' + billReview.billReviewID.toString()}>
                <td colSpan={4}>Bill Review (ID {billReview.billReviewID}, created at {formatDateTime(billReview.createDate)}, Project ID {billReview.projectID.toString()})<br />PM Reviewed: {billReview.isPMReviewed ? 'Y' : 'N'}, PA Reviewed: {billReview.isPAReviewed ? 'Y' : 'N'}, Last modifed by {billReview.modifyBy}</td>
            </tr>
            {items.map(item =>
                <tr key={'item' + item.itemBRID.toString()}>
                    <td>{item.itemBRID.toString()}</td>
                    <td>{item.description ?? ""}</td>
                    <td className="text-black-50 text-center">{item.isPMReviewed ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>}</td>
                    <td className="text-black-50 text-center">{item.isPAReviewed ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>}</td>
                </tr>
            )}
        </React.Fragment>

    };

    const renderBillReviewItemsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>Item ID</th>
                                <th>Description</th>
                                <th style={{ textAlign: 'center' }}>PM Reviewed</th>
                                <th style={{ textAlign: 'center' }}>PA Reviewed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupedItems.size > 0 ? Array.from(groupedItems).map(([, itemsInGroup]) => renderBillReview(itemsInGroup))
                                : <tr><td colSpan={5} style={{ textAlign: 'center' }}>No incomplete bill review items were found.</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for incomplete bill review items" totalCount={totalCount}
                    itemCount={billReviewItems.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="billReviewItems" />
            </div>
        </>);
    }

    let contents = loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderBillReviewItemsTable();

    return (
        <div>
            {contents}
        </div>
    );
};

export default IncompleteBillReviewItems;