import { useState, useEffect } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import SaleItemBaseCodes from './SaleItemBaseCodes';
import SaleItemBaseCodesFilter from './SaleItemBaseCodesFilter';
import SaleItemsByBaseCodesList from './SaleItemsByBaseCodesList';
import authService from '../api-authorization/AuthorizeService'
import { GlPeriodViewModel, RemoveSaleItemsByBaseCodes } from './GlPeriodDetailsLayout';

type SaleItemsByBaseCodesProps = {
    period: GlPeriodViewModel;
    refreshData: () => void;
};

const SaleItemsByBaseCodes = (props: SaleItemsByBaseCodesProps) => {
    const [openSections, setOpenSections] = useState(["BaseCodesFilter", "BaseCodeDetails", "SalesItems"]);
    const [submittingData, setSubmittingData] = useState(false);
    const [statusText, setStatusText] = useState("");
    const [basedCodeIDsForSjRemoval, setBasedCodeIDsForSjRemoval] = useState(props.period.basedCodeIDsForSjRemoval);
    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    useEffect(() => {
        const updateBasedCodeIDsForSjRemoval = async () => {
            setSubmittingData(true);

            const url = `glPeriodFacts/${props.period.factId!.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                method: 'PATCH',
                headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
                body: JSON.stringify({
                    basedCodeIDsForSjRemoval: basedCodeIDsForSjRemoval,
                })
            });
            if (response.ok) {
                try {
                    await response.json();
                    setStatusText("");
                    props.refreshData();
                } catch (error) {
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setStatusText(await response.text());
            }
            setSubmittingData(false);
        }
        if (props.period.factId && basedCodeIDsForSjRemoval !== props.period.basedCodeIDsForSjRemoval) {
            updateBasedCodeIDsForSjRemoval();
        }
    }, [basedCodeIDsForSjRemoval, props, props.period.basedCodeIDsForSjRemoval, props.period.factId, props.refreshData]);

    return (<div className="container">
        {statusText ? <div className="row"><div className="col-12"><div className="alert alert-danger" role="alert">
            <div>{statusText}</div>
        </div></div></div> : <></>}
        {
            //@ts-ignore
            <Accordion open={openSections} toggle={setOpenSection}>
                <AccordionItem>
                    <AccordionHeader targetId="BaseCodesFilter">Base Codes Filter</AccordionHeader>
                    <AccordionBody accordionId="BaseCodesFilter">
                        <SaleItemBaseCodesFilter basedCodeIDsForSjRemoval={basedCodeIDsForSjRemoval}
                            setBasedCodeIDsForSjRemoval={setBasedCodeIDsForSjRemoval}
                            submittingData={submittingData} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="BaseCodeDetails">Base Code Details</AccordionHeader>
                    <AccordionBody accordionId="BaseCodeDetails">
                        <SaleItemBaseCodes baseCodeIds={basedCodeIDsForSjRemoval} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="SalesItems">Sales Items to Be Deleted</AccordionHeader>
                    <AccordionBody accordionId="SalesItems">
                        <SaleItemsByBaseCodesList periodId={props.period.periodId} baseCodeIds={basedCodeIDsForSjRemoval} version={props.period.flags & RemoveSaleItemsByBaseCodes } />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        }
    </div>);
};

export default SaleItemsByBaseCodes;