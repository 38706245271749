import { useId } from 'react';
import { GlPeriod } from '../GlPeriods';
import { formatDate } from '../../Utils';

type PreviousPeriodProps = {
    previousPeriod: GlPeriod | null;
};

const PreviousPeriod = (props: PreviousPeriodProps) => {
    const prevPeriod = props.previousPeriod;
    const prevPeriodFormId = useId();

    return (prevPeriod ? <>
        <div className="row mb-3">
            <div className="form-group col-md-3">
                <label htmlFor={prevPeriodFormId + "-Id"}>ID:</label>
                <input type="text" className="form-control" id={prevPeriodFormId + "-Id"} readOnly value={prevPeriod.periodId.toString()} />
            </div>
            <div className="form-group col-md-3">
                <label htmlFor={prevPeriodFormId + "-code"}>Code:</label>
                <input type="text" className="form-control" id={prevPeriodFormId + "-code"} readOnly value={prevPeriod.periodCode} />
            </div>
            <div className="form-group col-md-3">
                <label htmlFor={prevPeriodFormId + "-startDate"}>Start:</label>
                <input type="text" className="form-control" id={prevPeriodFormId + "-startDate"} readOnly value={formatDate(prevPeriod.startDate)} />
            </div>
            <div className="form-group col-md-3">
                <label htmlFor={prevPeriodFormId + "-endDate"}>End:</label>
                <input type="text" className="form-control" id={prevPeriodFormId + "-endDate"} readOnly value={formatDate(prevPeriod.endDate)} />
            </div>
        </div>
        <div className="row mt-2 mb-2 text-secondary">
            <div className="form-group col-md-6 d-flex justify-content-center">
                {prevPeriod.isOpen ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>}&nbsp;Is Open
            </div>
            <div className="form-group col-md-6 d-flex justify-content-center">
                {prevPeriod.allowAdjustments ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>}&nbsp;Allow Adjustments
            </div>
        </div>
    </> : <p><em>No prior accounting period was found.</em></p>);
};

export default PreviousPeriod;