import { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import AdjustmentItems from './AdjustmentItems';
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';
import HybridReimbursements from './HybridReimbursements';
import HybridTimesheetItems from './HybridTimesheetItems';

type HybridProps = {
    period: GlPeriodViewModel;
};

const Hybrid = (props: HybridProps) => {
    const [openSections, setOpenSections] = useState(["TimesheetItems", "Reimbursements"]);
    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    return (<div className="container">
        {
            //@ts-ignore
            <Accordion open={openSections} toggle={setOpenSection}>
                <AccordionItem>
                    <AccordionHeader targetId="TimesheetItems">Timesheet Items</AccordionHeader>
                    <AccordionBody accordionId="TimesheetItems">
                        <HybridTimesheetItems period={props.period} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="Reimbursements">Employee Reimbursement Items</AccordionHeader>
                    <AccordionBody accordionId="Reimbursements">
                        <HybridReimbursements period={props.period} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="AdjustmentItems">Adjustment</AccordionHeader>
                    <AccordionBody accordionId="AdjustmentItems">
                        <AdjustmentItems adjustmentId={props.period.billedHybridAdjustmentId} />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        }
    </div>);
};

export default Hybrid;