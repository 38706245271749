import { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import AdjustmentItems from './AdjustmentItems';
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';
import WipPurchases from './WipPurchases';
import WipReimbursements from './WipReimbursements';
import WipTimesheetItems from './WipTimesheetItems';

type WipProps = {
    period: GlPeriodViewModel;
};

const Wip = (props: WipProps) => {
    const [openSections, setOpenSections] = useState(["TimesheetItems", "Reimbursements", "Purchases", "AdjustmentItems"]);
    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    return (<div className="container">
        {
            //@ts-ignore
            <Accordion open={openSections} toggle={setOpenSection}>
                <AccordionItem>
                    <AccordionHeader targetId="TimesheetItems">Timesheet Items</AccordionHeader>
                    <AccordionBody accordionId="TimesheetItems">
                        <WipTimesheetItems period={props.period} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="Reimbursements">Employee Reimbursement Items</AccordionHeader>
                    <AccordionBody accordionId="Reimbursements">
                        <WipReimbursements period={props.period} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="Purchases">Purchase Items</AccordionHeader>
                    <AccordionBody accordionId="Purchases">
                        <WipPurchases period={props.period} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="AdjustmentItems">Adjustment for WIP</AccordionHeader>
                    <AccordionBody accordionId="AdjustmentItems">
                        <AdjustmentItems adjustmentId={props.period.wipAdjustmentId} />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        }
    </div>);
};

export default Wip;