import React, { useState, useEffect } from 'react';
import { formatDate } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';
import { GlPeriod } from '../GlPeriods';

type HybridReimbursementsProps = {
    period: GlPeriod;
};

type ReimbursementItemView = {
    projectCode: string | null,
    projectName: string | null,
    projectPath: string | null,
    reimbursementID: number;
    employeeName: string;
    employeeOrgUnitName: string;
    modifyDate: string;
    id: number;
    transDate: string | null;
    invoiceDate: string | null;
};

const buildProjectDesc = (item: ReimbursementItemView): string => {
    let projectDesc = item.projectName ?? "";

    if (item.projectPath && item.projectName?.toLowerCase() !== item.projectPath?.toLowerCase()) {
        projectDesc += " ( Path: " + item.projectPath + ")";
    }

    return projectDesc;
}

const listPageSize = 25;

const HybridReimbursements = (props: HybridReimbursementsProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reimbursementItems, setReimbursementItems] = useState<ReimbursementItemView[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateReimbursementItems = async () => {
            const queryParams = new URLSearchParams({
                periodId: props.period.periodId.toString(),
                contractTypes: "8",
                startIndex: startIndex.toString(),
                pageSize: listPageSize.toString(),
            });
            const url = `reimbursementItems/byContractType?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setReimbursementItems(data.items ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setReimbursementItems([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setReimbursementItems([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }

        populateReimbursementItems();
    }, [startIndex, props.period]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const itemsByOrgUnits = reimbursementItems.reduce((groups, item) => {
        const groupKey = (item.employeeOrgUnitName ?? '');
        if (!groups.has(groupKey)) {
            groups.set(groupKey, []);
        }
        groups.get(groupKey)?.push(item);
        return groups;
    }, new Map<string, ReimbursementItemView[]>());

    const renderItemsByReimbursement = (items: ReimbursementItemView[]) => {
        const groupLeader = items[0];
        const groupKey = groupLeader.reimbursementID.toString();

        return <React.Fragment key={groupKey}>
            <tr key={groupKey}>
                <td colSpan={4}>Reimbursement ID {groupKey}: {groupLeader.employeeName}, {formatDate(groupLeader.modifyDate)}</td>
            </tr>
            {items.map(item => <tr key={item.id.toString()}>
                <td>{item.id.toString()}</td>
                <td title={buildProjectDesc(item)}>{item.projectCode ?? ""}</td>
                <td>{item.transDate ? formatDate(item.transDate) : ""}</td>
                <td>{item.invoiceDate ? formatDate(item.invoiceDate) : ""}</td>
            </tr>)}
        </React.Fragment>
    };

    const renderByOrgUnits = (items: ReimbursementItemView[]) => {
        const groupLeader = items[0];
        const groupKey = (groupLeader.employeeOrgUnitName ?? '');

        const itemsByTimesheet = items.reduce((groups, item) => {
            const groupKey = item.reimbursementID.toString();
            if (!groups.has(groupKey)) {
                groups.set(groupKey, []);
            }
            groups.get(groupKey)?.push(item);
            return groups;
        }, new Map<string, ReimbursementItemView[]>());

        return <React.Fragment key={groupKey}>
            <tr key={groupKey}>
                <td colSpan={4}>Org Unit "{groupLeader.employeeOrgUnitName ?? '-'}"</td>
            </tr>
            {Array.from(itemsByTimesheet).map(([, itemsInGroup]) => renderItemsByReimbursement(itemsInGroup))}
        </React.Fragment>
    };

    const renderReimbursementItemsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>Item ID</th>
                                <th>Project</th>
                                <th>Trans Date</th>
                                <th>Invoice Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {itemsByOrgUnits.size > 0 ? Array.from(itemsByOrgUnits).map(([, itemsInGroup]) => renderByOrgUnits(itemsInGroup))
                                : <tr><td colSpan={4} style={{ textAlign: 'center' }}> The hybrid reimbursement items list is empty.</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for timesheet items" totalCount={totalCount}
                    itemCount={reimbursementItems.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="hybridReimbursementItems" />
            </div>
        </>);
    }

    return loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderReimbursementItemsTable()
};

export default HybridReimbursements;