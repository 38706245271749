import { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import AdjustmentItems from './AdjustmentItems';
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';
import TnMandNteReimbursements from './TnMandNteReimbursements';
import TnMandNteTimesheetItems from './TnMandNteTimesheetItems';

type TnMandNteProps = {
    period: GlPeriodViewModel;
};

const TnMandNte = (props: TnMandNteProps) => {
    const [openSections, setOpenSections] = useState(["TimesheetItems", "Reimbursements"]);
    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    return (<div className="container">
        {
            //@ts-ignore
            <Accordion open={openSections} toggle={setOpenSection}>
                <AccordionItem>
                    <AccordionHeader targetId="TimesheetItems">Timesheet Items</AccordionHeader>
                    <AccordionBody accordionId="TimesheetItems">
                        <TnMandNteTimesheetItems period={props.period} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="Reimbursements">Employee Reimbursement Items</AccordionHeader>
                    <AccordionBody accordionId="Reimbursements">
                        <TnMandNteReimbursements period={props.period} />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="AdjustmentItems">Adjustment</AccordionHeader>
                    <AccordionBody accordionId="AdjustmentItems">
                        <AdjustmentItems adjustmentId={props.period.billedTnMandNTEAdjustmentId} />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        }
    </div>);
};

export default TnMandNte;