import { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { GlPeriodViewModel, UpdateTimesheetFlag } from './GlPeriodDetailsLayout';
import TimesheetItemsWithMismatchingOrgUnits from './TimesheetItemsWithMismatchingOrgUnits';

type OrgUnitsOnTimesheetsProps = {
    period: GlPeriodViewModel;
};

const OrgUnitsOnTimesheets = (props: OrgUnitsOnTimesheetsProps) => {
    const [openSections, setOpenSections] = useState(["TimesheetItems"]);
    const setOpenSection = (id: string) => {
        const index = openSections.indexOf(id);
        if (index === -1) {
            setOpenSections([...openSections, id]);
        } else {
            openSections.splice(index, 1);
            setOpenSections([...openSections]);
        }
    };

    return (<div className="container">
        {
            //@ts-ignore
            <Accordion open={openSections} toggle={setOpenSection}>
                <AccordionItem>
                    <AccordionHeader targetId="TimesheetItems">Timesheet Items with Charge/Home Org Units differ from Invoice Org Unit</AccordionHeader>
                    <AccordionBody accordionId="TimesheetItems">
                        <TimesheetItemsWithMismatchingOrgUnits period={props.period} version={props.period.flags & UpdateTimesheetFlag} />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        }
    </div>);
};

export default OrgUnitsOnTimesheets;