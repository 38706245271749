import { useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

type TablePagerProps = {
    name: string;
    itemCount: number;
    totalCount: number;
    pageSize: number;
    ariaLabel: string;
    onPageChanged: (page: number) => void;
};

const TablePager = (props: TablePagerProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageCount = Math.floor((props.totalCount + props.pageSize - 1) / props.pageSize);
    const pageArray = [];
    if (pageCount <= 5) {
        for (let i = 1; i <= pageCount; i++) {
            pageArray.push(i);
        }
    } else if (currentPage <= 3) {
        for (let i = 1; i <= 5; i++) {
            pageArray.push(i);
        }
    } else if (currentPage >= pageCount - 2) {
        for (let i = pageCount - 4; i <= pageCount; i++) {
            pageArray.push(i);
        }
    } else {
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            pageArray.push(i);
        }
    } 
    const onClickPage = (e: React.MouseEvent<HTMLAnchorElement>, page: number) => {
        e.preventDefault();
        setCurrentPage(page);
        props.onPageChanged(page);
        return false;
    };

    const currentIndex = (currentPage - 1) * props.pageSize + 1;

    return (pageCount <= 1 ? <div className="col-12"></div> : <>
        <div className="col-md-5 d-flex justify-content-start align-items-center">Showing {currentIndex} to {currentIndex + props.itemCount - 1} of {props.totalCount} entries</div>
        <div className="col-md-7 d-flex justify-content-end align-items-center">
            <Pagination aria-label={props.ariaLabel} style={ {marginTop: "auto", marginBottom: "auto" }} >
        <PaginationItem disabled={currentPage === 1}>
            <PaginationLink first href="#" onClick={(e) => onClickPage(e, 1)} />
        </PaginationItem>
        <PaginationItem disabled={currentPage === 1}>
            <PaginationLink previous href="#" onClick={(e) => onClickPage(e, 1)} />
        </PaginationItem>
        {
            pageArray.map(page => (
                <PaginationItem active={page === currentPage} key={props.name + page.toString()}>
                    <PaginationLink href="#" onClick={(e) => onClickPage(e, page)}>{page}</PaginationLink>
                </PaginationItem>
            ))
        }
        <PaginationItem disabled={currentPage === pageCount}>
            <PaginationLink next href="#" onClick={(e) => onClickPage(e, currentPage + 1)} />
        </PaginationItem>
        <PaginationItem disabled={currentPage === pageCount}>
            <PaginationLink last href="#" onClick={(e) => onClickPage(e, pageCount)} />
        </PaginationItem>
    </Pagination>
        </div>
    </>);
};

export default TablePager;