import { ReactNode } from 'react';
import { Button, Container } from 'reactstrap';
import NavMenu from './NavMenu';

type Props = {
    children: ReactNode;
};

const Layout = (props: Props) => {

    return (
        <Container id="mainLayout">
            <NavMenu />
            <Container tag="main">
                {props.children}
            </Container>
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <div className="col-md-4 d-flex align-items-center">
                    <span className="ms-3 mb-3 mb-md-0 text-muted">&copy;2023 - Forensic Analytical Consulting Services, Inc.</span>
                </div>

                <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li className="ms-3"><Button className="btn btn-link text-muted p-1" href="#"><i className="bi bi-twitter"></i></Button></li>
                    <li className="ms-3"><Button className="btn btn-link text-muted p-1" href="#"><i className="bi bi-instagram"></i></Button></li>
                    <li className="ms-3"><Button className="btn btn-link text-muted p-1 me-3" href="#"><i className="bi bi-facebook"></i></Button></li>
                </ul>
            </footer>
        </Container>
    );
}

export default Layout;