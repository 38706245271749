import React, { useState, useEffect } from 'react';
import { formatCurrency } from '../../Utils';
import authService from '../api-authorization/AuthorizeService'
import TablePager from '../TablePager';
import { GlPeriodViewModel } from './GlPeriodDetailsLayout';

type ExpenseItemsProps = {
    period: GlPeriodViewModel;
};

type AdjustmentItem = {
    gjLineID: number;
    projectID: number | null;
    glid: number;
    glPeriodCode: string;
    amount: number;
    glComments: string | null
};

const listPageSize = 25;

const ExpenseItems = (props: ExpenseItemsProps) => {
    const [statusText, setStatusText] = useState("Loading...");
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [expenseItems, setExpenseItems] = useState<AdjustmentItem[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const populateSaleItems = async () => {
            const queryParams = new URLSearchParams({
                periodId: props.period.periodId.toString(),
                periodCode: props.period.periodCode
            });
            const url = `adjustmentItems/getExpenseItemsToBeCreated?${queryParams.toString()}`;
            const token = await authService.getAccessToken();
            const response = await fetch(url, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                try {
                    const data = await response.json();
                    setTotalCount(data.totalCount);
                    setExpenseItems(data.items ?? []);
                    setStatusText("");
                } catch (error) {
                    setTotalCount(0);
                    setExpenseItems([]);
                    console.error('An error occurred:', error);
                    const errorString = error instanceof Error ? error.message : 'An unknown error occurred';
                    setStatusText(errorString);
                }
            }
            else {
                setTotalCount(0);
                setExpenseItems([]);
                setStatusText(await response.text());
            }
            setLoading(false);
        }

        populateSaleItems();
    }, [props.period.periodCode, props.period.periodId]);

    const onPageChanged = (page: number) => {
        setStartIndex((page - 1) * listPageSize);
    };

    const pagedItems = expenseItems.slice(startIndex, startIndex + listPageSize);

    const renderExpenseItemsTable = () => {
        return (<>
            <div className="row">
                <div className="col-12 table-responsive-md">
                    <table className="table table-striped" aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <th>Item ID</th>
                                <th>Project ID</th>
                                <th>GL Account ID</th>
                                <th>Amount</th>
                                <th>Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pagedItems.length > 0 ? pagedItems.map((item) => <tr key={'expenseItem' + item.gjLineID.toString()}>
                                <td>{item.gjLineID.toString()}</td>
                                <td>{item.projectID?.toString() ?? ''}</td>
                                <td>{item.glid.toString()}</td>
                                <td>{formatCurrency(item.amount)}</td>
                                <td><small>{item.glComments ?? ''}</small></td>
                            </tr>)
                            : <tr><td colSpan={5} style={{ textAlign: 'center' }}>The expense items list is empty.</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <TablePager ariaLabel="Page navigation for expense items" totalCount={totalCount}
                    itemCount={pagedItems.length} pageSize={listPageSize} onPageChanged={onPageChanged} name="expenseItems" />
            </div>
        </>);
    }

    return loading || statusText
        ? <p><em>{statusText}</em></p>
        : renderExpenseItemsTable()
};

export default ExpenseItems;