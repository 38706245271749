export const formatCurrency = (amount: number): string => {
    if (amount < 0) {
        return '(' + amount.toFixed(2) + ')';
    }
    return amount.toFixed(2);
};

export const formatDate = (isoDatetimeString: string): string => {
    const date = new Date(isoDatetimeString);

    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
};

export const formatDateTime = (isoDatetimeString: string): string => {
    const date = new Date(isoDatetimeString);

    return date.toLocaleString('en-US', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};

export const formatDateIgnoreTimeZone = (isoDatetimeString: string): string => {
    // Extract the year, month, and day from the ISO string
    // ISO format: YYYY-MM-DDTHH:MM:SSZ
    const year = isoDatetimeString.substring(0, 4);
    const month = isoDatetimeString.substring(5, 7);
    const day = isoDatetimeString.substring(8, 10);

    // Format the date in 'MM/DD/YYYY' format
    return `${month}/${day}/${year}`;
};

export const addDays = (date: Date, days: number): Date => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
};

export class PostError {
    constructor(public errorCode: string, public message: string) { }
}
